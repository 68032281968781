import { Grid, InputAdornment, TextField } from "@mui/material";
import React, { useEffect, useState } from "react";
import { withNavBars } from "../../common/hocs";
import { ListTable, SearchIcon } from "../../components";
import { makeStyles } from "@mui/styles";
import { useDispatch, useSelector } from "react-redux";
import { Footer, NavBar, Sidebar } from "../../components";
import {
  getInvoiceData,
  clearInvoice,
  setPagenoInvoice,
} from "../../app/reducers/invoiceSlice";
import moment from "moment";
import { getPermittedStore } from "../../app/reducers/storeLoginSlice";
import _ from "lodash";
import { useNavigate, useLocation } from "react-router-dom";
import { useIsMount } from "../../common/hooks/customHooks";
import InvoiceDetails from "./InvoiceDetails";

const useStyles = makeStyles({
  notchedOutline: {
    borderRadius: "35px !important",
  },
});

function Invoices(props) {
  const classes = useStyles();
  const dispatch = useDispatch();
  const history = useNavigate();
  const isMount = useIsMount();
  const location = useLocation();

  const storeChange = useSelector(
    (state) => state.storeLoginReducer.selectedStoreId
  );
  const invoiceList = useSelector(
    (state) => state.invoiceReducer.invoiceDetails
  );
  const pageNo = useSelector((state) => state.invoiceReducer.pageNoInvoice);
  const hasMore = useSelector((state) => state.invoiceReducer.hasMoreInvoice);
  const [loading, setLoading] = useState(false);
  const [searchStr, setSearchStr] = useState("");
  const [open, setOpen] = useState(false);
  const [openOrderCheckout, setOpenOrderCheckout] = useState(false);
  const [invoiceDetails, setInvoiceDetails] = useState({});
  const [openSideBar, setOpenSideBar] = useState(false);

  const handleBack = () => {
    setOpenOrderCheckout(false);
  };

  const handleDrawerOpen = () => {
    setOpenSideBar(true);
  };

  const handleDrawerClose = () => {
    setOpenSideBar(false);
  };

  useEffect(() => {
    const token = localStorage.getItem("eyePlay-auth-token");
    if (_.isEmpty(token)) {
      history("/");
    }

    if (token && _.isEmpty(storeChange)) {
      dispatch(getPermittedStore());
    }
  }, []);

  useEffect(() => {
    if (!_.isEmpty(storeChange)) {
      dispatch(clearInvoice());
      dispatch(getInvoiceData({ page: 0, search: searchStr }));
    }
  }, [storeChange]);

  useEffect(() => {
    setLoading(false);
  }, [invoiceList]);

  useEffect(() => {
    const timeOut = setTimeout(
      () =>
        !isMount ? dispatch(getInvoiceData({ page: 0, search: searchStr })) : "",
      1000
    );
    return () => clearTimeout(timeOut);
  }, [searchStr]);

  let tableData = [];

  invoiceList?.map((invoice) => {
    const invoices = {
      invoiceID:
        invoice?.invoiceId + " / " + moment(invoice?.invoiceDate).format("llll"),
      name: invoice?.customerDetails.name,
      orderID:
        invoice?.orderId + " / " + moment(invoice?.invoiceDate).format("llll"),
      amount: invoice?.inclGst,
      data: invoice,
    };
    tableData.push(invoices);

    return tableData;
  });

  const handleView = (e, data, index, type) => {
    if (type === "view") {
      setOpenOrderCheckout(true);
      setInvoiceDetails(data.data);
    } else {
      window.open(data.data.invoiceUrl, '_blank');
    }
  };

  const handleSearch = (e) => {
    setSearchStr(e.target.value);
  };

  const handleLoadmore = () => {
    setLoading(true);
    dispatch(setPagenoInvoice({ page: pageNo + 1, search: searchStr }));
    dispatch(getInvoiceData({ page: pageNo + 1, search: searchStr }));
  };

  return (
    <>
      <div>
        <NavBar
          handleDrawerOpen={handleDrawerOpen}
          header={"Orders"}
          isBack={openOrderCheckout}
          handleBack={handleBack}
        />
        <Sidebar
          handleDrawerClose={handleDrawerClose}
          open={openSideBar}
          location={location}
        />
      </div>
      {openOrderCheckout !== true ? (
        < div >
          <Grid
            container
            alignItems={"center"}
            sx={{
              position: "fixed",
              backgroundColor: "#f5f5f5",
              width: "calc(100% - 20px)",
            }}
          >
            <Grid item xs={6} sx={{ padding: "12px 24px" }}>
              <TextField
                id="input-with-icon-textfield"
                placeholder="Search InvoiceId/OrderId"
                fullWidth
                variant="outlined"
                value={searchStr}
                onChange={handleSearch}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <SearchIcon width={"16"} height={"16"} />
                    </InputAdornment>
                  ),
                  classes: {
                    root: classes.notchedOutline,
                  },
                }}
                style={{ backgroundColor: "#fff", borderRadius: "35px" }}
              />
            </Grid>
          </Grid >
          <div
            style={{
              padding: "90px 24px 40px",
              height: "calc(100vh - 200px)",
              overflowY: "auto",
              overflowX: "hidden",
            }}
          >
            <ListTable
              dataList={tableData}
              isLoading={loading}
              handleLoadmore={handleLoadmore}
              hasMore={hasMore}
              handleView={handleView}
              Header={[
                "Invoice ID/Date",
                "Consumer Name",
                "Order ID/Date",
                "Invoice Amount",
                "Download Invoice",
                "Actions"
              ]}
              tableData={[
                { type: ["TEXT"], name: "invoiceID" },
                { type: ["TEXT"], name: "name" },
                { type: ["TEXT"], name: "orderID" },
                { type: ["TEXT"], name: "amount" },
                { type: ["DOWNLOAD"], name: "" },
                { type: ["VIEW"], name: "" },
              ]}
            />
          </div>
        </div >) : (
          <InvoiceDetails order={invoiceDetails} handleBack={handleBack} />
      )}
    </>
  );
}

export default Invoices;
