import React, { useEffect, useState } from "react";
import _ from "lodash";
import {
  Grid,
  List,
  ListItem,
  ListItemButton,
  ListItemText,
  Paper,
  Typography,
  Autocomplete,
  TextField,
  Button,
  IconButton,
  Dialog,
} from "@mui/material";
import { Footer } from "../../../components";
import { Card } from "../../../components";
import { useNavigate } from "react-router-dom";
import LensCatalogDialog from "./LensCatalogDialog";
import { useDispatch, useSelector } from "react-redux";
import { setMultiSelectedProductsData } from "../../../app/reducers/librarySlice";
import {
  setIndexPreload,
  setCatalogPreload,
  setKeyPreload,
  setSelectedIndexPreload,
} from "../../../app/reducers/lenscatalogSlice";
import SentimentVeryDissatisfiedIcon from "@mui/icons-material/SentimentVeryDissatisfied";
import {
  getLensCatalog,
  setSelectedLensCatalog,
} from "../../../app/reducers/lenscatalogSlice";
import { makeStyles } from "@mui/styles";
import OtherBrandModal from "./OtherBrandModal";
import { setFrame } from "../../../app/reducers/frameSlice";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import LensIndex from "../../../assets/LensIndex.jpg";
import CloseIcon from "@mui/icons-material/Close";

const useStyles = makeStyles({
  outline: {
    "& .MuiOutlinedInput-notchedOutline": {
      borderRadius: "25px",
    },
  },
});

const LensCatalog = (props) => {
  const classes = useStyles();
  const history = useNavigate();
  const dispatch = useDispatch();

  const productData = useSelector((state) =>
    _.isEmpty(state.libraryReducer.selectedProduct)
      ? state.demoReducer.selectedProductDemo
      : state.libraryReducer.selectedProduct
  );

  const selectedPrescription = useSelector(
    (state) => state.prescriptionReducer.selectedPrescription
  );
  const lensCatalogData = useSelector(
    (state) => state.lenscatalogReducer.lenCatalog
  );
  const initialCatalogData = useSelector(
    (state) => state.lenscatalogReducer.initialLensCatalog
  );
  const preCatalogData = useSelector(
    (state) => state.lenscatalogReducer.selectedIndexList
  );
  const preCatalogSelected = useSelector(
    (state) => state.lenscatalogReducer.selectedLens
  );
  const preselectedLensList = useSelector(
    (state) => state.lenscatalogReducer.selectedLensList
  );

  const preKey = useSelector((state) => state.lenscatalogReducer.keyVal);

  const preBrand = useSelector(
    (state) => state.lenscatalogReducer.brandSelected
  );

  const preSelectedIndex = useSelector(
    (state) => state.lenscatalogReducer?.preSelectedIndex
  );

  const indexxlist = _.isEmpty(preCatalogData) ? [] : preCatalogData;

  const srtedCatalog = _.isEmpty(preselectedLensList)
    ? initialCatalogData
    : preselectedLensList;

  const progressive = _.isEmpty(preCatalogSelected)
    ? ""
    : _.isEmpty(preCatalogSelected?.lensCategory)
    ? ""
    : preCatalogSelected?.lensCategory;

  const setLissstBorder = {
    key: _.isEmpty(preCatalogSelected)
      ? ""
      : preCatalogSelected?.lensItems?.index,
    border: "2px solid #03A5A5",
  };

  const brand = _.isEmpty(preCatalogSelected)
    ? "Regular"
    : preCatalogSelected?.brand;

  const cardddBorder = {
    key: _.isEmpty(preKey) ? "" : preKey?.key,
    border: "2px solid #03A5A5",
  };

  const preSlctdIndx = _.isEmpty(preSelectedIndex) ? {} : preSelectedIndex;

  const [sortedCatalog, setSortedCatalog] = useState(srtedCatalog);
  const [selectedLens, setSelectedLens] = useState({});
  const [lensBrand, setLensBrand] = useState(brand);
  const [indexList, setIndexList] = useState(indexxlist);
  const [selectedIndex, setSelectedIndex] = useState(preSlctdIndx);
  const [progressiveFilter, setProgressiveFilter] = useState(progressive);
  const [isCatalogDataPresent, setIsCatalogDataPresent] = useState(false);
  const [openOtherBrandModal, setOpenOtherBrandModal] = useState(false);

  const [open, setOpen] = useState(false);
  const [listBorder, setListBorder] = useState(setLissstBorder);
  const [cardBorder, setCardBorder] = useState(cardddBorder);
  const [features, setFeatures] = useState([]);
  const [sellPrice, setSellPrice] = useState("");
  const [lensImage, setLensImage] = useState("");
  const [url, setUrl] = useState("");
  const [showModal, setShowModal] = useState(false);

  useEffect(() => {
    dispatch(getLensCatalog(selectedPrescription));
    setIsCatalogDataPresent(true);
  }, [dispatch, selectedPrescription]);

  useEffect(() => {
    setSortedCatalog(
      _.isEmpty(preselectedLensList) ? initialCatalogData : preselectedLensList
    );
  }, [isCatalogDataPresent, initialCatalogData, preselectedLensList, preBrand]);

  const expandImage = (event, feature, price, image) => {
    setSellPrice(price);
    setFeatures(feature || []);
    setLensImage(
      image ||
        "https://images.unsplash.com/photo-1453728013993-6d66e9c9123a?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxzZWFyY2h8MXx8Y2FtZXJhJTIwZm9jdXN8ZW58MHx8MHx8&auto=format&fit=crop&w=500&q=60"
    );
    setOpen(true);
  };

  const generateIndexForSelectedLens = (data) => {
    setIndexList(data);
    dispatch(setIndexPreload(data));
  };

  const onSelectCard = (e, key, catalog) => {
    dispatch(setCatalogPreload(sortedCatalog));
    dispatch(setKeyPreload({ key: key }));
    setIndexList([]);
    setSelectedIndex({});
    setListBorder({
      key: "",
      border: "1px solid #e6e6e6",
    });
    setCardBorder({ key: key, border: "2px solid #03A5A5" });
    setSelectedLens(catalog);
    generateIndexForSelectedLens(catalog?.lens);
  };

  const cardStyles = {
    width: "100%",
  };

  const handleClose = () => {
    setOpen(false);
    setOpenOtherBrandModal(false);
  };

  const handleListButton = (e, value) => {
    setListBorder({ key: value.index, border: "2px solid #03A5A5" });
    setSelectedIndex(value);
    dispatch(setSelectedIndexPreload(value));
  };

  const handleBackButton = () => {
    history("/add-prescription");
  };

  const handleContinueButton = () => {
    if (!_.isEmpty(selectedIndex)) {
      const data = {
        productId: productData.productId,
        isProduct: false,
        lensItems: {
          lensId: selectedIndex?._id,
          lensType: selectedIndex?.lensType,
          brandType: selectedIndex?.brandType,
          lensCoating: selectedIndex?.lensCoating,
          index: selectedIndex?.index,
          gst: 12,
          price: Number(selectedIndex?.sellPrice / 1.12).toFixed(2),
          gstValue: Number(selectedIndex?.sellPrice).toFixed(2) - Number(selectedIndex?.sellPrice / 1.12).toFixed(2),
          costPrice: selectedIndex?.costPrice,
          brand: selectedIndex?.brand,
          total:  Number(Number(selectedIndex?.sellPrice / 1.12).toFixed(2)) + Number(Number(selectedIndex?.sellPrice).toFixed(2) - Number(selectedIndex?.sellPrice / 1.12).toFixed(2))
        },
      };

      const dataSelected = {
        productId: productData.productId,
        isProduct: false,
        lensItems: {
          lensId: selectedIndex?._id,
          lensType: selectedIndex?.lensType,
          lensCoating: selectedIndex?.lensCoating,
          index: selectedIndex?.index,
          gst: 12,
          price: Number(selectedIndex?.sellPrice / 1.12).toFixed(2),
          gstValue: Number(selectedIndex?.sellPrice).toFixed(2) - Number(selectedIndex?.sellPrice / 1.12).toFixed(2),
          costPrice: selectedIndex?.costPrice,
          total:  Number(selectedIndex?.sellPrice / 1.12).toFixed(2) + (Number(selectedIndex?.sellPrice).toFixed(2) - Number(selectedIndex?.sellPrice / 1.12).toFixed(2))
        },
        brand: _.isEmpty(lensBrand) ? "REGULAR" : lensBrand,
        lensCategory: _.isEmpty(selectedPrescription)
          ? ""
          : selectedPrescription === "Progressive"
          ? selectedIndex?.lensCategory
          : "",
      };
      dispatch(setSelectedLensCatalog(dataSelected));

      if (
        selectedIndex?.lensType === "SINGLE VISION" &&
        productData.frameType === "FULLRIM"
      ) {
        let frameSize = { a: "0", b: "0", ed: "0", fh: "0", d: "0" };
        data.frameSize = frameSize;
        dispatch(setFrame(frameSize));
        history("/check-out");
      } else {
        history("/frame-orientation");
      }
      dispatch(setMultiSelectedProductsData(data));
    } else {
      history("/frame-orientation");
    }
  };

  const handleProgressiveChange = (event, category) => {
    setProgressiveFilter(category);
    const sortedData = lensCatalogData.filter((catalog) => {
      return (
        catalog?._id?.lensCategory === category &&
        catalog?._id?.brand === _.upperCase(lensBrand)
      );
    });
    setSortedCatalog(sortedData);
    setIndexList([]);
    setSelectedIndex({});
    setSelectedLens({});
    setCardBorder({
      key: "",
      border: "1px solid #e6e6e6",
    });
    setListBorder({
      key: "",
      border: "1px solid #e6e6e6",
    });
  };

  const handleChange = (newValue) => {
    if (newValue !== "Regular") {
      setOpenOtherBrandModal(true);
    } else {
      setLensBrand(newValue);
      const sortedData = lensCatalogData.filter(
        (catalog) => catalog?._id?.brand === _.upperCase(newValue)
      );
      setSortedCatalog(sortedData);
      setProgressiveFilter("");
      setIndexList([]);
      setSelectedIndex({});
      setSelectedLens({});
      setCardBorder({
        key: "",
        border: "1px solid #e6e6e6",
      });
      setListBorder({
        key: "",
        border: "1px solid #e6e6e6",
      });
    }
  };

  const closeModal = () => {
    setShowModal(false);
  };
  const openImage = (e, screen) => {
    if (screen === "index") {
      setUrl(LensIndex);
      setShowModal(true);
    } else {
      setShowModal(true);
      setUrl(LensIndex);
    }
  };

  return (
    <div
      style={{
        backgroundColor: "rgb(245, 245, 245)",
        height:
          lensBrand?.length > 1 && sortedCatalog?.length > 2 ? "auto" : "100vh",
      }}
    >
      <Grid
        container
        direction="row"
        justifyContent="center"
        alignItems="flex-start"
      >
        <Grid
          item
          xs={12}
          md={8}
          lg={8}
          sx={{
            padding: "20px",
            paddingRight: "40px",
            borderRight: "1px solid rgb(230, 230, 230)",
          }}
        >
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              margin: "10px",
            }}
          >
            {selectedPrescription === "Progressive" ? (
              <div
                style={{
                  display: "flex",
                  justifyContent: "flex-start",
                  alignItems: "center",
                }}
              >
                <Typography
                  sx={{
                    fontSize: 16,
                    display: "flex",
                    fontWeight: 550,
                    color: "#03A5A5",
                    pb: "0px",
                  }}
                >
                  CHOOSE LENS CATALOG
                </Typography>
                <IconButton
                  size="small"
                  sx={{
                    ml: "5px",
                    color: "#03A5A5",
                  }}
                  onClick={(event) => openImage(event, "progressive")}
                >
                  <InfoOutlinedIcon />
                </IconButton>
              </div>
            ) : (
              <Typography
                sx={{
                  fontSize: 16,
                  display: "flex",
                  fontWeight: 550,
                  color: "#03A5A5",
                  pb: "0px",
                }}
              >
                CHOOSE LENS CATALOG
              </Typography>
            )}
            {
              <Autocomplete
                disablePortal
                id="combo-box-demo"
                options={["Regular", "Other Brands"]}
                sx={{ width: 180 }}
                defaultValue={brand}
                onChange={(event, newValue) => {
                  handleChange(newValue);
                }}
                size="small"
                renderInput={(params) => (
                  <TextField
                    className={classes.outline}
                    sx={{ backgroundColor: "#fff", borderRadius: "25px" }}
                    {...params}
                    placeholder="Select"
                  />
                )}
              />
            }
          </div>
          {selectedPrescription === "Progressive" ? (
            <div
              style={{
                display: "flex",
                justifyContent: "flex-start",
                alignItems: "center",
              }}
            >
              <Button
                variant="contained"
                size="small"
                name="standard"
                disableRipple="true"
                disableFocusRipple="true"
                sx={{
                  fontSize: 12,
                  background:
                    progressiveFilter === "Standard"
                      ? "#03a5a5"
                      : "rgb(245, 245, 245)",
                  mx: "5px",
                  borderRadius: "20px",
                  color:
                    progressiveFilter === "Standard"
                      ? "#fff"
                      : "rgb(150, 150, 150)",
                  "&.MuiButtonBase-root:hover": {
                    bgcolor:
                      progressiveFilter === "Standard"
                        ? "#03a5a5"
                        : "rgb(245, 245, 245)",
                  },
                  textTransform: "none",
                  padding: "5px",
                  minWidth: "100px",
                }}
                onClick={(event) => handleProgressiveChange(event, "Standard")}
              >
                Standard
              </Button>
              <Button
                variant="contained"
                name="premium"
                size="small"
                sx={{
                  fontSize: 12,
                  background:
                    progressiveFilter === "Premium"
                      ? "#03a5a5"
                      : "rgb(245, 245, 245)",
                  mx: "5px",
                  borderRadius: "20px",
                  color:
                    progressiveFilter === "Premium"
                      ? "#fff"
                      : "rgb(150, 150, 150)",
                  "&.MuiButtonBase-root:hover": {
                    bgcolor:
                      progressiveFilter === "Premium"
                        ? "#03a5a5"
                        : "rgb(245, 245, 245)",
                  },
                  textTransform: "none",
                  padding: "5px",
                  minWidth: "100px",
                }}
                onClick={(event) => handleProgressiveChange(event, "Premium")}
              >
                Premium
              </Button>
              <Button
                variant="contained"
                name="advanced"
                size="small"
                sx={{
                  fontSize: 12,
                  background:
                    progressiveFilter === "Advanced"
                      ? "#03a5a5"
                      : "rgb(245, 245, 245)",
                  mx: "5px",
                  borderRadius: "20px",
                  color:
                    progressiveFilter === "Advanced"
                      ? "#fff"
                      : "rgb(150, 150, 150)",
                  "&.MuiButtonBase-root:hover": {
                    bgcolor:
                      progressiveFilter === "Advanced"
                        ? "#03a5a5"
                        : "rgb(245, 245, 245)",
                  },
                  textTransform: "none",
                  padding: "5px",
                  minWidth: "100px",
                }}
                onClick={(event) => handleProgressiveChange(event, "Advanced")}
              >
                Advanced
              </Button>
            </div>
          ) : (
            " "
          )}

          <Grid container spacing={2} sx={{ pt: "10px", mb: "90px" }}>
            {_.isEmpty(sortedCatalog) ? (
              <Grid item xs={12} sm={6} md={6}>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <div
                    style={{
                      color: "#000",
                      padding: "100px",
                      alignContent: "center",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    No data found
                    <SentimentVeryDissatisfiedIcon />
                  </div>
                </div>
              </Grid>
            ) : (
              sortedCatalog?.map((catalog, index) => (
                <Grid item xs={12} sm={6} md={6}>
                  <div
                    onClick={(event) => onSelectCard(event, index, catalog)}
                    key={index}
                    style={{
                      border:
                        cardBorder.key === index
                          ? cardBorder.border
                          : "1px solid #e6e6e6",
                      borderRadius: "4px",
                    }}
                  >
                    <Card
                      lists={catalog}
                      isCatalog={true}
                      width={cardStyles}
                      expandImage={expandImage}
                    />
                  </div>
                </Grid>
              ))
            )}
          </Grid>
        </Grid>
        <Grid item xs={12} md={4} lg={4} sx={{ p: "20px", mt: "18px" }}>
          <div
            style={{
              display: "flex",
              justifyContent: "flex-start",
              alignItems: "center",
            }}
          >
            <Typography
              sx={{
                pb: "20px",
                fontSize: 16,
                display: "flex",
                fontWeight: 550,
                color: "#03A5A5",
              }}
            >
              CHOOSE INDEX
            </Typography>
            <IconButton
              size="small"
              sx={{
                ml: "5px",
                pb: "26px",
                color: "#03A5A5",
              }}
              onClick={(event) => openImage(event, "index")}
            >
              <InfoOutlinedIcon />
            </IconButton>
          </div>

          <Typography>choose Lens to display indexes</Typography>
          <Paper elevation={1} sx={{ padding: "24px" }}>
            <List
              sx={{
                width: "100%",
                maxWidth: "90%",
                bgcolor: "background.paper",
              }}
            >
              {indexList.map((value) => (
                <ListItemButton
                  sx={{
                    border:
                      listBorder.key === value.index
                        ? listBorder.border
                        : "1px solid #e6e6e6",
                    mb: "12px",
                    borderRadius: "4px",
                    pl: "8px",
                    cursor: "pointer",
                    display: _.isEmpty(value.index) ? "none" : "",
                  }}
                >
                  <ListItem
                    key={value.index}
                    sx={{
                      color:
                        listBorder.key === value.index ? "#03a5a5" : "#000",
                    }}
                    disableGutters
                    onClick={(event) => handleListButton(event, value)}
                    secondaryAction={
                      <div
                        style={{
                          color:
                            listBorder.key === value.index ? "#03a5a5" : "#000",
                        }}
                      >
                        &#8377;
                        {value.sellPrice}
                      </div>
                    }
                  >
                    <ListItemText primary={`${value.index}`} />
                  </ListItem>
                </ListItemButton>
              ))}
            </List>
          </Paper>
        </Grid>
      </Grid>
      <Footer
        back={"Back"}
        handleBackButton={handleBackButton}
        handleContinueButton={handleContinueButton}
        onContinue={"Continue"}
      />
      <OtherBrandModal
        open={openOtherBrandModal}
        handleClose={handleClose}
        selectedPrescription={selectedPrescription}
        productData={productData}
      />
      <LensCatalogDialog
        isOpen={open}
        handleClose={handleClose}
        features={features}
        sellPrice={sellPrice}
        lensImage={lensImage}
      />
      <Dialog
        open={showModal}
        maxWidth
        onClose={closeModal}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        sx={{ borderRadius: "8px" }}
      >
        <div style={{ margin: "5px" }}>
          <div>
            <IconButton
              aria-label="close"
              onClick={closeModal}
              sx={{
                position: "absolute",
                right: 8,
                top: 8,
              }}
            >
              <CloseIcon fontSize="small" />
            </IconButton>
          </div>
          <img src={url} width="500px" height="300px" alt="Image broken"></img>
        </div>
      </Dialog>
    </div>
  );
};

export default LensCatalog;
