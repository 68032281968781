import { Card, CardContent, Grid, TextField, Typography, Select, MenuItem } from "@mui/material";
import { ContinueButton, DownloadIcon, ListTable, OrderIndentIcon } from "../../components";
import React, { useState, useEffect } from "react";
import { withNavBars } from "../../common/hocs";
import { useNavigate } from "react-router-dom";
import { getPermittedStore } from "../../app/reducers/storeLoginSlice";
import { useDispatch, useSelector } from "react-redux";
import { getReportDatav2 } from "../../app/reducers/reportSlice";
import moment from "moment";
import _, { countBy } from "lodash";
import { MobileDatePicker } from "@mui/x-date-pickers/MobileDatePicker";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import html2canvas from "html2canvas";
import { jsPDF } from "jspdf";

function Eodv2(props) {
    const today = moment().format("YYYY-MM-DD");
    const currentDisplayDate = moment().format("llll");

    const history = useNavigate();
    const dispatch = useDispatch();
    const [searchDate, setSearchDate] = useState(today);
    const [startDate, setStartDate] = useState(today);
    const [endDate, setEndDate] = useState(today);
    const [displayStartDate, setDisplayStartDate] = useState(currentDisplayDate);
    const [displayEndDate, setDisplayEndDate] = useState(currentDisplayDate);
    const [reportPeriod, setReportPeriod] = useState('today');
    const [payload, setPayLoad] = useState({ start: startDate, end: endDate });

    useEffect(() => {
        const token = localStorage.getItem("eyePlay-auth-token");
        if (token) {
            dispatch(getPermittedStore());
        }
    }, []);

    const storeChange = useSelector(
        (state) => state.storeLoginReducer.selectedStoreId
    );

    useEffect(() => {
        const token = localStorage.getItem("eyePlay-auth-token");
        if (_.isEmpty(token)) {
            history("/");
        }

        if (token && _.isEmpty(storeChange)) {
            dispatch(getPermittedStore());
        }
    }, []);

    useEffect(() => {
        if (!_.isEmpty(storeChange)) {
            dispatch(getReportDatav2(payload));
        }
    }, [storeChange, payload]);

    const eodData = useSelector(
        (state) => state.reportReducerv2.response
    );

    const tableData = []
    const totalSalesSummary = eodData ? eodData.totalSalesSummary : []
    const array = totalSalesSummary.map((data) => {
        const table = {
            type: data.type,
            totalOrders: data.totalOrders,
            taxableAmount: Number(data.taxableAmount).toFixed(2),
            totalGst: Number(data.totalGst).toFixed(2),
            totalAmount: Number(data.totalAmount).toFixed(2),
            abv: _.isNaN(Number(data.totalAmount) / Number(data.totalOrders)) ? '0.00' : Number(Number(data.totalAmount) / Number(data.totalOrders)).toFixed(2)
        }
        tableData.push(table);
        return tableData;
    })
    const ncoTableData = [];
    const nonClosedOrderSummmary = eodData ? eodData.nonClosedOrderSummmary : []
    nonClosedOrderSummmary?.orders?.map((data) => {
        const table = {
            _id: data._id,
            orderDate: moment(data?.orderDate).format("llll"),
            status: data.status,
            totalCardPayment: Number(data.totalCardPayment).toFixed(2),
            totalCashPayment: Number(data.totalCashPayment).toFixed(2),
            totalUpiPayment: Number(data.totalUpiPayment).toFixed(2),
            orderAmount: Number(data.orderAmount).toFixed(2),
            advanceAmount: Number(data.advanceAmount).toFixed(2),
            balanceAmount: Number(Number(data.orderAmount) - Number(data.advanceAmount)).toFixed(2)
        };
        ncoTableData.push(table);

        return ncoTableData;
    });

    const orders = [];
    const orderData = eodData?.orderSummary ? eodData.orderSummary.orders : [];

    orderData?.map((order) => {
        const ordData = {
            _id: order._id,
            orderAmount: Number(order.orderAmount).toFixed(2),
            totalCashPayment: Number(order.totalCashPayment).toFixed(2),
            totalCardPayment: Number(order.totalCardPayment).toFixed(2),
            totalUpiPayment: Number(order.totalUpiPayment).toFixed(2)
        }

        orders.push(ordData);

        return orders;
    });

    const invoices = [];
    const invoiceData = eodData?.invoiceSummary ? eodData.invoiceSummary.invoices : [];

    invoiceData?.map((invoice) => {
        const invData = {
            _id: invoice._id,
            orderAmount: Number(invoice.orderAmount).toFixed(2),
            totalCashPayment: Number(invoice.totalCashPayment).toFixed(2),
            totalCardPayment: Number(invoice.totalCardPayment).toFixed(2),
            totalUpiPayment: Number(invoice.totalUpiPayment).toFixed(2)
        }

        invoices.push(invData);

        return invoices;
    });

    const DownloadIconProps = {
        color: "#FFF"
    };

    const downloadPdfDocument = () => {
        const input = document.querySelector("#downloadable");
        html2canvas(input)
            .then((canvas) => {
                const imgData = canvas.toDataURL('image/png');
                const pdf = new jsPDF();
                pdf.addImage(imgData, 'JPEG', 10, 10, 190, 270);
                pdf.save("eod_report" + searchDate + ".pdf");
            })
    }

    const handleChange = (date) => {
        setSearchDate(moment(date).format("YYYY-MM-DD"));
        setStartDate(moment(date).format("YYYY-MM-DD"));
        setEndDate(moment(date).format("YYYY-MM-DD"));
        setDisplayStartDate(moment(date).format("llll"));
        setDisplayEndDate(moment(date).format("llll"));
    };

    const handlePeriodChange = (event) => {
        let start = today;
        let end = today;
        const period = event.target.value;
        setReportPeriod(period);
        if (period === 'week') {
            start = moment().startOf('week').format("YYYY-MM-DD");
            end = moment().endOf('week').format("YYYY-MM-DD");
        } else if (period === 'month') {
            start = moment().startOf('month').format("YYYY-MM-DD");
            end = moment().endOf('month').format("YYYY-MM-DD");
        }
        setStartDate(start);
        setEndDate(end);
        setDisplayStartDate(moment(start).format("llll"));
        setDisplayEndDate(moment(end).format("llll"));
    }

    useEffect(() => {
        setPayLoad({ start: startDate, end: endDate });
    }, [startDate, endDate]);

    return (
        <div>
            <Grid
                container
                alignItems={"center"}
                sx={{
                    position: "fixed",
                    backgroundColor: "#f5f5f5",
                    width: "calc(100% - 20px)",
                    position: "static"
                }}
                justifyContent="flex-end"
            >
                <Grid
                    container
                    alignItems={"center"}
                    sx={{

                    }}
                >
                    <Grid item xs={6} sx={{ padding: "12px 24px" }}>
                        <Typography
                            variant="h6"
                            sx={{
                                fontSize: "20px",
                                fontWeight: 600,
                            }}
                        >
                            Sales Summary
                        </Typography>
                    </Grid>
                    <Grid
                        item
                        xs={6}
                        sx={{
                            padding: "12px 24px",
                            display: "flex",
                            justifyContent: "flex-end",
                        }}
                    >
                        <Select
                            value={reportPeriod}
                            onChange={handlePeriodChange}
                            displayEmpty
                            inputProps={{ 'aria-label': 'Without label' }}
                            sx={{
                                borderRadius: "100px",
                                width: "200px"
                            }}
                            style={{ marginRight: "15px" }}
                        >
                            <MenuItem value={'today'}>Today</MenuItem>
                            <MenuItem value={'week'}>This Week</MenuItem>
                            <MenuItem value={'month'}>This Month</MenuItem>
                        </Select>
                        <LocalizationProvider dateAdapter={AdapterDateFns} >
                            <MobileDatePicker
                                inputFormat="MM/dd/yyyy"
                                value={searchDate}
                                onChange={handleChange}
                                renderInput={(params) => (
                                    <TextField  {...params} sx={{
                                        "& .MuiInputBase-root": {
                                            borderRadius: "100px"
                                        },
                                    }} />
                                )}
                                className="date-picker"
                            />
                        </LocalizationProvider>
                        <ContinueButton onClick={downloadPdfDocument}>
                            <DownloadIcon {...DownloadIconProps} />  <span style={{ margin: "5px" }}>Download</span>
                        </ContinueButton>
                    </Grid>
                </Grid>

            </Grid>
            <div id="downloadable">
                <div
                    style={{
                        padding: "0px 24px 40px",
                        overflowY: "auto",
                        overflowX: "hidden",
                    }}
                >
                    <ListTable
                        dataList={eodData ? tableData : []}
                        Header={[
                            "",
                            "Orders",
                            "Taxable",
                            "GST",
                            "Total",
                            "ABV"
                        ]}
                        tableData={[
                            { type: ["EOD"], name: "type" },
                            { type: ["TEXT"], name: "totalOrders" },
                            { type: ["TEXT"], name: "taxableAmount", align: "right" },
                            { type: ["TEXT"], name: "totalGst", align: "right" },
                            { type: ["TEXT"], name: "totalAmount", align: "right" },
                            { type: ["TEXT"], name: "abv", align: "right" },
                        ]}
                        rightAlign={["Taxable", "GST", "Total", "ABV"]}
                    />
                </div>

                <Grid
                    container
                    alignItems={"center"}
                    sx={{
                        position: "fixed",
                        backgroundColor: "#f5f5f5",
                        width: "calc(100% - 20px)",
                        position: "static",
                    }}
                    justifyContent="flex-end"
                >
                    <Grid item xs={12} sx={{ padding: "12px 24px", justifyContent: "flex-start", }}>
                        <Typography
                            variant="h6"
                            sx={{
                                fontSize: "20px",
                                fontWeight: 600,
                            }}
                        >
                            Order Summary for, <span style={{ fontWeight: 300 }}> ({displayStartDate} - {displayEndDate})</span>
                        </Typography>
                    </Grid>
                </Grid>
                <div
                    style={{
                        display: "flex",
                        justifyContent: "space-between",
                        aligntItems: "center", padding: "12px 24px",
                    }}
                >
                    <Grid container >
                        <Card sx={{ minWidth: 150, marginRight: 10 }}>
                            <CardContent sx={{ flex: '3 0 auto' }}>
                                <Typography
                                    sx={{
                                        color: "#827e7e",
                                        fontSize: "12px",
                                        fontWeight: 500,
                                        fontStretch: "normal",
                                        fontStyle: "normal",
                                        lineHeight: 1.88,
                                        letterSpacing: "0.8px",
                                        textAlign: "left",
                                    }} >
                                    CASH
                                </Typography>
                                <Typography
                                    sx={{
                                        color: "#2b2b2b",
                                        fontSize: "20px",
                                        fontWeight: 600,
                                        fontStretch: "normal",
                                        fontStyle: "normal",
                                        lineHeight: 1.88,
                                        letterSpacing: "0.8px",
                                        textAlign: "left",
                                    }} >
                                    &#8377; {eodData && Number(eodData.orderSummary.totalCashPayment).toFixed(2)}
                                </Typography>
                            </CardContent>
                        </Card >
                        <Card sx={{ minWidth: 150, marginRight: 10 }}>
                            <CardContent sx={{ flex: '3 0 auto' }}>
                                <Typography
                                    sx={{
                                        color: "#827e7e",
                                        fontSize: "12px",
                                        fontWeight: 500,
                                        fontStretch: "normal",
                                        fontStyle: "normal",
                                        lineHeight: 1.88,
                                        letterSpacing: "0.8px",
                                        textAlign: "left",
                                    }} >
                                    CARD
                                </Typography>
                                <Typography
                                    sx={{
                                        color: "#2b2b2b",
                                        fontSize: "20px",
                                        fontWeight: 600,
                                        fontStretch: "normal",
                                        fontStyle: "normal",
                                        lineHeight: 1.88,
                                        letterSpacing: "0.8px",
                                        textAlign: "left",
                                    }}
                                >
                                    &#8377; {eodData && Number(eodData.orderSummary.totalCardPayment).toFixed(2)}
                                </Typography>
                            </CardContent>
                        </Card>
                        <Card sx={{ minWidth: 150, marginRight: 10 }}>
                            <CardContent sx={{ flex: '3 0 auto' }}>
                                <Typography
                                    sx={{
                                        color: "#827e7e",
                                        fontSize: "12px",
                                        fontWeight: 500,
                                        fontStretch: "normal",
                                        fontStyle: "normal",
                                        lineHeight: 1.88,
                                        letterSpacing: "0.8px",
                                        textAlign: "left",
                                    }}
                                >
                                    UPI
                                </Typography>
                                <Typography
                                    sx={{
                                        color: "#2b2b2b",
                                        fontSize: "20px",
                                        fontWeight: 600,
                                        fontStretch: "normal",
                                        fontStyle: "normal",
                                        lineHeight: 1.88,
                                        letterSpacing: "0.8px",
                                        textAlign: "left",
                                    }}
                                >
                                    &#8377; {eodData && Number(eodData.orderSummary.totalUpiPayment).toFixed(2)}
                                </Typography>
                            </CardContent>
                        </Card>
                        <Card sx={{ minWidth: 150, marginRight: 10 }}>
                            <CardContent sx={{ flex: '3 0 auto' }}>
                                <Typography
                                    sx={{
                                        color: "#827e7e",
                                        fontSize: "12px",
                                        fontWeight: 500,
                                        fontStretch: "normal",
                                        fontStyle: "normal",
                                        lineHeight: 1.88,
                                        letterSpacing: "0.8px",
                                        textAlign: "left",
                                    }} >
                                    TOTAL
                                </Typography>
                                <Typography
                                    sx={{
                                        color: "#2b2b2b",
                                        fontSize: "20px",
                                        fontWeight: 600,
                                        fontStretch: "normal",
                                        fontStyle: "normal",
                                        lineHeight: 1.88,
                                        letterSpacing: "0.8px",
                                        textAlign: "left",
                                    }}
                                >
                                    &#8377; {eodData && Number(eodData.orderSummary.totalPayment).toFixed(2)}
                                </Typography>
                            </CardContent>
                        </Card>

                    </Grid>
                </div>
                <div
                    style={{
                        padding: "0px 24px 40px",
                        overflowY: "auto",
                        overflowX: "hidden",
                    }}
                >
                    <ListTable
                        dataList={orders ? orders : []}
                        Header={[
                            "Order ID",
                            "Order Amount",
                            "Cash",
                            "Card",
                            "UPI"
                        ]}
                        tableData={[
                            { type: ["TEXT"], name: "_id" },
                            { type: ["TEXT"], name: "orderAmount", align: "right" },
                            { type: ["TEXT"], name: "totalCashPayment", align: "right" },
                            { type: ["TEXT"], name: "totalCardPayment", align: "right" },
                            { type: ["TEXT"], name: "totalUpiPayment", align: "right" },
                        ]}
                        rightAlign={[
                            "Order Amount",
                            "Cash",
                            "Card",
                            "UPI"
                        ]}
                    />
                </div>

                <Grid
                    container
                    alignItems={"center"}
                    sx={{
                        position: "fixed",
                        backgroundColor: "#f5f5f5",
                        width: "calc(100% - 20px)",
                        position: "static"
                    }}
                    justifyContent="flex-end"
                >
                    <Grid item xs={12} sx={{ padding: "12px 24px", justifyContent: "flex-start", }}>
                        <Typography
                            variant="h6"
                            sx={{
                                fontSize: "20px",
                                fontWeight: 600,
                            }}

                        >
                            Invoice Summary for, <span style={{ fontWeight: 300 }}> ({displayStartDate} - {displayEndDate})</span>
                        </Typography>
                    </Grid>
                </Grid>
                <div
                    style={{
                        display: "flex",
                        justifyContent: "space-between",
                        aligntItems: "center", padding: "12px 24px",
                    }}
                >
                    <Grid container >
                        <Card sx={{ minWidth: 150, marginRight: 10 }}>
                            <CardContent sx={{ flex: '3 0 auto' }}>
                                <Typography
                                    sx={{
                                        color: "#827e7e",
                                        fontSize: "12px",
                                        fontWeight: 500,
                                        fontStretch: "normal",
                                        fontStyle: "normal",
                                        lineHeight: 1.88,
                                        letterSpacing: "0.8px",
                                        textAlign: "left",
                                    }} >
                                    CASH
                                </Typography>
                                <Typography
                                    sx={{
                                        color: "#2b2b2b",
                                        fontSize: "20px",
                                        fontWeight: 600,
                                        fontStretch: "normal",
                                        fontStyle: "normal",
                                        lineHeight: 1.88,
                                        letterSpacing: "0.8px",
                                        textAlign: "left",
                                    }} >
                                    &#8377; {eodData && Number(eodData.invoiceSummary.totalCashPayment).toFixed(2)}
                                </Typography>
                            </CardContent>
                        </Card >
                        <Card sx={{ minWidth: 150, marginRight: 10 }}>
                            <CardContent sx={{ flex: '3 0 auto' }}>
                                <Typography
                                    sx={{
                                        color: "#827e7e",
                                        fontSize: "12px",
                                        fontWeight: 500,
                                        fontStretch: "normal",
                                        fontStyle: "normal",
                                        lineHeight: 1.88,
                                        letterSpacing: "0.8px",
                                        textAlign: "left",
                                    }} >
                                    CARD
                                </Typography>
                                <Typography
                                    sx={{
                                        color: "#2b2b2b",
                                        fontSize: "20px",
                                        fontWeight: 600,
                                        fontStretch: "normal",
                                        fontStyle: "normal",
                                        lineHeight: 1.88,
                                        letterSpacing: "0.8px",
                                        textAlign: "left",
                                    }}
                                >
                                    &#8377; {eodData && Number(eodData.invoiceSummary.totalCardPayment).toFixed(2)}
                                </Typography>
                            </CardContent>
                        </Card>
                        <Card sx={{ minWidth: 150, marginRight: 10 }}>
                            <CardContent sx={{ flex: '3 0 auto' }}>
                                <Typography
                                    sx={{
                                        color: "#827e7e",
                                        fontSize: "12px",
                                        fontWeight: 500,
                                        fontStretch: "normal",
                                        fontStyle: "normal",
                                        lineHeight: 1.88,
                                        letterSpacing: "0.8px",
                                        textAlign: "left",
                                    }}
                                >
                                    UPI
                                </Typography>
                                <Typography
                                    sx={{
                                        color: "#2b2b2b",
                                        fontSize: "20px",
                                        fontWeight: 600,
                                        fontStretch: "normal",
                                        fontStyle: "normal",
                                        lineHeight: 1.88,
                                        letterSpacing: "0.8px",
                                        textAlign: "left",
                                    }}
                                >
                                    &#8377; {eodData && Number(eodData.invoiceSummary.totalUpiPayment).toFixed(2)}
                                </Typography>
                            </CardContent>
                        </Card>
                        <Card sx={{ minWidth: 150, marginRight: 10 }}>
                            <CardContent sx={{ flex: '3 0 auto' }}>
                                <Typography
                                    sx={{
                                        color: "#827e7e",
                                        fontSize: "12px",
                                        fontWeight: 500,
                                        fontStretch: "normal",
                                        fontStyle: "normal",
                                        lineHeight: 1.88,
                                        letterSpacing: "0.8px",
                                        textAlign: "left",
                                    }} >
                                    TOTAL
                                </Typography>
                                <Typography
                                    sx={{
                                        color: "#2b2b2b",
                                        fontSize: "20px",
                                        fontWeight: 600,
                                        fontStretch: "normal",
                                        fontStyle: "normal",
                                        lineHeight: 1.88,
                                        letterSpacing: "0.8px",
                                        textAlign: "left",
                                    }}
                                >
                                    &#8377; {eodData && Number(eodData.invoiceSummary.totalPayment).toFixed(2)}
                                </Typography>
                            </CardContent>
                        </Card>

                    </Grid>
                </div>
                <div
                    style={{
                        padding: "0px 24px 40px",
                        overflowY: "auto",
                        overflowX: "hidden",
                    }}
                >
                    <ListTable
                        dataList={invoices ? invoices : []}
                        Header={[
                            "Invoice ID",
                            "Invoice Amount",
                            "Cash",
                            "Card",
                            "UPI",
                        ]}
                        tableData={[
                            { type: ["TEXT"], name: "_id" },
                            { type: ["TEXT"], name: "orderAmount", align: "right" },
                            { type: ["TEXT"], name: "totalCashPayment", align: "right" },
                            { type: ["TEXT"], name: "totalCardPayment", align: "right" },
                            { type: ["TEXT"], name: "totalUpiPayment", align: "right" },
                        ]}
                        rightAlign={[
                            "Invoice Amount",
                            "Cash",
                            "Card",
                            "UPI"
                        ]}
                    />
                </div>

                <Grid
                    container
                    alignItems={"center"}
                    sx={{
                        position: "fixed",
                        backgroundColor: "#f5f5f5",
                        width: "calc(100% - 20px)",
                        position: "static"
                    }}
                    justifyContent="flex-end"
                >
                    <Grid item xs={12} sx={{ padding: "12px 24px", justifyContent: "flex-start", }}>
                        <Typography
                            variant="h6"
                            sx={{
                                fontSize: "20px",
                                fontWeight: 600,
                            }}
                        >
                            Payment Summary for, <span style={{ fontWeight: 300 }}> ({displayStartDate} - {displayEndDate})</span>
                        </Typography>
                    </Grid>
                </Grid>
                <div
                    style={{
                        display: "flex",
                        justifyContent: "space-between",
                        aligntItems: "center", padding: "12px 24px",
                    }}
                >
                    <Grid container >
                        <Card sx={{ minWidth: 150, marginRight: 10 }}>
                            <CardContent sx={{ flex: '3 0 auto' }}>
                                <Typography
                                    sx={{
                                        color: "#827e7e",
                                        fontSize: "12px",
                                        fontWeight: 500,
                                        fontStretch: "normal",
                                        fontStyle: "normal",
                                        lineHeight: 1.88,
                                        letterSpacing: "0.8px",
                                        textAlign: "left",
                                    }} >
                                    CASH
                                </Typography>
                                <Typography
                                    sx={{
                                        color: "#2b2b2b",
                                        fontSize: "20px",
                                        fontWeight: 600,
                                        fontStretch: "normal",
                                        fontStyle: "normal",
                                        lineHeight: 1.88,
                                        letterSpacing: "0.8px",
                                        textAlign: "left",
                                    }} >
                                    &#8377; {eodData && Number(eodData.totalPaymentsReceived.totalCashPayment).toFixed(2)}
                                </Typography>
                            </CardContent>
                        </Card >
                        <Card sx={{ minWidth: 150, marginRight: 10 }}>
                            <CardContent sx={{ flex: '3 0 auto' }}>
                                <Typography
                                    sx={{
                                        color: "#827e7e",
                                        fontSize: "12px",
                                        fontWeight: 500,
                                        fontStretch: "normal",
                                        fontStyle: "normal",
                                        lineHeight: 1.88,
                                        letterSpacing: "0.8px",
                                        textAlign: "left",
                                    }} >
                                    CARD
                                </Typography>
                                <Typography
                                    sx={{
                                        color: "#2b2b2b",
                                        fontSize: "20px",
                                        fontWeight: 600,
                                        fontStretch: "normal",
                                        fontStyle: "normal",
                                        lineHeight: 1.88,
                                        letterSpacing: "0.8px",
                                        textAlign: "left",
                                    }}
                                >
                                    &#8377; {eodData && Number(eodData.totalPaymentsReceived.totalCardPayment).toFixed(2)}
                                </Typography>
                            </CardContent>
                        </Card>
                        <Card sx={{ minWidth: 150, marginRight: 10 }}>
                            <CardContent sx={{ flex: '3 0 auto' }}>
                                <Typography
                                    sx={{
                                        color: "#827e7e",
                                        fontSize: "12px",
                                        fontWeight: 500,
                                        fontStretch: "normal",
                                        fontStyle: "normal",
                                        lineHeight: 1.88,
                                        letterSpacing: "0.8px",
                                        textAlign: "left",
                                    }}
                                >
                                    UPI
                                </Typography>
                                <Typography
                                    sx={{
                                        color: "#2b2b2b",
                                        fontSize: "20px",
                                        fontWeight: 600,
                                        fontStretch: "normal",
                                        fontStyle: "normal",
                                        lineHeight: 1.88,
                                        letterSpacing: "0.8px",
                                        textAlign: "left",
                                    }}
                                >
                                    &#8377; {eodData && Number(eodData.totalPaymentsReceived.totalUpiPayment).toFixed(2)}
                                </Typography>
                            </CardContent>
                        </Card>
                        <Card sx={{ minWidth: 150, marginRight: 10 }}>
                            <CardContent sx={{ flex: '3 0 auto' }}>
                                <Typography
                                    sx={{
                                        color: "#827e7e",
                                        fontSize: "12px",
                                        fontWeight: 500,
                                        fontStretch: "normal",
                                        fontStyle: "normal",
                                        lineHeight: 1.88,
                                        letterSpacing: "0.8px",
                                        textAlign: "left",
                                    }} >
                                    TOTAL
                                </Typography>
                                <Typography
                                    sx={{
                                        color: "#2b2b2b",
                                        fontSize: "20px",
                                        fontWeight: 600,
                                        fontStretch: "normal",
                                        fontStyle: "normal",
                                        lineHeight: 1.88,
                                        letterSpacing: "0.8px",
                                        textAlign: "left",
                                    }}
                                >
                                    &#8377; {eodData && Number(eodData.totalPaymentsReceived.totalPayment).toFixed(2)}
                                </Typography>
                            </CardContent>
                        </Card>

                    </Grid>
                </div>

                <Grid
                    container
                    alignItems={"center"}
                    sx={{
                        position: "fixed",
                        backgroundColor: "#f5f5f5",
                        width: "calc(100% - 20px)",
                        position: "static"
                    }}
                    justifyContent="flex-end"
                >
                    <Grid item xs={12} sx={{ padding: "12px 24px", justifyContent: "flex-start", }}>
                        <Typography
                            variant="h6"
                            sx={{
                                fontSize: "20px",
                                fontWeight: 600,
                            }}
                        >
                            Pending Orders until, <span style={{ fontWeight: 300 }}> ({displayEndDate})</span>
                        </Typography>
                    </Grid>
                </Grid>
                <div
                    style={{
                        display: "flex",
                        justifyContent: "space-between",
                        aligntItems: "center", padding: "12px 24px",
                    }}
                >
                    <Grid container >
                        <Card sx={{ minWidth: 150, marginRight: 10 }}>
                            <CardContent sx={{ flex: '3 0 auto' }}>
                                <Typography
                                    sx={{
                                        color: "#827e7e",
                                        fontSize: "12px",
                                        fontWeight: 500,
                                        fontStretch: "normal",
                                        fontStyle: "normal",
                                        lineHeight: 1.88,
                                        letterSpacing: "0.8px",
                                        textAlign: "left",
                                    }} >
                                    TOTAL
                                </Typography>
                                <Typography
                                    sx={{
                                        color: "#2b2b2b",
                                        fontSize: "20px",
                                        fontWeight: 600,
                                        fontStretch: "normal",
                                        fontStyle: "normal",
                                        lineHeight: 1.88,
                                        letterSpacing: "0.8px",
                                        textAlign: "left",
                                    }} >
                                    &#8377; {eodData && Number(eodData.nonClosedOrderSummmary.totalOrderAmount).toFixed(2)}
                                </Typography>
                            </CardContent>
                        </Card >
                        <Card sx={{ minWidth: 150, marginRight: 10 }}>
                            <CardContent sx={{ flex: '3 0 auto' }}>
                                <Typography
                                    sx={{
                                        color: "#827e7e",
                                        fontSize: "12px",
                                        fontWeight: 500,
                                        fontStretch: "normal",
                                        fontStyle: "normal",
                                        lineHeight: 1.88,
                                        letterSpacing: "0.8px",
                                        textAlign: "left",
                                    }} >
                                    ADVANCE
                                </Typography>
                                <Typography
                                    sx={{
                                        color: "#2b2b2b",
                                        fontSize: "20px",
                                        fontWeight: 600,
                                        fontStretch: "normal",
                                        fontStyle: "normal",
                                        lineHeight: 1.88,
                                        letterSpacing: "0.8px",
                                        textAlign: "left",
                                    }}
                                >
                                    &#8377; {eodData && Number(eodData.nonClosedOrderSummmary.totalAdvanceAmount).toFixed(2)}
                                </Typography>
                            </CardContent>
                        </Card>
                        <Card sx={{ minWidth: 150, marginRight: 10 }}>
                            <CardContent sx={{ flex: '3 0 auto' }}>
                                <Typography
                                    sx={{
                                        color: "#827e7e",
                                        fontSize: "12px",
                                        fontWeight: 500,
                                        fontStretch: "normal",
                                        fontStyle: "normal",
                                        lineHeight: 1.88,
                                        letterSpacing: "0.8px",
                                        textAlign: "left",
                                    }}
                                >
                                    BALANCE
                                </Typography>
                                <Typography
                                    sx={{
                                        color: "#2b2b2b",
                                        fontSize: "20px",
                                        fontWeight: 600,
                                        fontStretch: "normal",
                                        fontStyle: "normal",
                                        lineHeight: 1.88,
                                        letterSpacing: "0.8px",
                                        textAlign: "left",
                                    }}
                                >
                                    &#8377; {eodData && Number(eodData.nonClosedOrderSummmary.todalBalance).toFixed(2)}
                                </Typography>
                            </CardContent>
                        </Card>
                        <Card sx={{ minWidth: 150, marginRight: 10 }}>
                            <CardContent sx={{ flex: '3 0 auto' }}>
                                <Typography
                                    sx={{
                                        color: "#827e7e",
                                        fontSize: "12px",
                                        fontWeight: 500,
                                        fontStretch: "normal",
                                        fontStyle: "normal",
                                        lineHeight: 1.88,
                                        letterSpacing: "0.8px",
                                        textAlign: "left",
                                    }} >
                                    PENDING
                                </Typography>
                                <Typography
                                    sx={{
                                        color: "#2b2b2b",
                                        fontSize: "20px",
                                        fontWeight: 600,
                                        fontStretch: "normal",
                                        fontStyle: "normal",
                                        lineHeight: 1.88,
                                        letterSpacing: "0.8px",
                                        textAlign: "left",
                                    }}
                                >
                                    {ncoTableData.length}
                                </Typography>
                            </CardContent>
                        </Card>

                    </Grid>
                </div>
                <div
                    style={{
                        padding: "0px 24px 40px",
                        overflowY: "auto",
                        overflowX: "hidden",
                    }}
                >
                    <ListTable
                        dataList={ncoTableData ? ncoTableData : []}
                        Header={[
                            "Order ID",
                            "Order Date",
                            "Status",
                            "Order Amount",
                            "Advance",
                            "Balance",
                        ]}
                        tableData={[
                            { type: ["TEXT"], name: "_id" },
                            { type: ["TEXT"], name: "orderDate" },
                            { type: ["TEXT"], name: "status" },
                            { type: ["TEXT"], name: "orderAmount", align: "right" },
                            { type: ["TEXT"], name: "advanceAmount", align: "right" },
                            { type: ["TEXT"], name: "balanceAmount", align: "right" },
                        ]}
                        rightAlign={["Order Amount", "Advance", "Balance"]}
                    />
                </div>
            </div>
        </div>
    );
}

export default withNavBars(Eodv2);
