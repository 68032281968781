import React, { useState, useEffect } from "react";
import moment from "moment";
import {
  Grid,
  Box,
  Typography,
  Divider,
  Dialog,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  TextField,
  FormControlLabel,
  Checkbox,
  RadioGroup,
  Radio,
} from "@mui/material";
import { TickIcon } from "../../components/svg/TickIcon";
import CheckoutBox from "../Inventory/Checkout/CheckoutBox";
import { SmsIcon } from "../../components/svg/SmsIcon";
import { CallIcon } from "../../components/svg/CallIcon";
import { FrameIcon } from "../../components/svg/FrameIcon";
import { styled } from "@mui/material/styles";
import { Footer, NavBar } from "../../components";
import { useNavigate } from "react-router-dom";
import ViewDetail from "../Inventory/Checkout/ViewDetail";
import CloseIcon from "@mui/icons-material/Close";
import { ContinueButton } from "../../components/wrapperComponents";
import {
  confirmDelivery,
  closeOrder,
  qualitySave,
} from "../../app/reducers/salesSlice";
import { useDispatch, useSelector } from "react-redux";
import _, { isEmpty } from "lodash";
import { HeadTypography, LabelTypography, useStyles } from "./styles";
import { closeOrderError } from "../../app/reducers/salesSlice";
import { AlertComp } from "../../components/Alert";
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import AssignmentIcon from '@mui/icons-material/Assignment';

const OrderCheckout = (props) => {
  const { order } = props;
  const { products } = props.order;
  const classes = useStyles();

  const dispatch = useDispatch();

  const history = useNavigate();

  const closeOrderErrorMessage = useSelector(
    (state) => state.salesReducer.closeOrderErrorMessage
  );

  const closeOrderSuccessMessage = useSelector(
    (state) => state.salesReducer.closeOrderSuccessMessage
  );

  const [viewDetail, setViewDetail] = useState(false);
  const [viewDetailData, setViewDetailData] = useState({});

  const [delivery, setDelivery] = useState(false);
  const [deliveryComments, setDeliveryComments] = useState("");

  const [quality, setQuality] = useState(false);
  const [checked, setChecked] = useState(true);
  const [qualityCheckBox, setQualityCheckBox] = useState("pass");
  const [qualityComments, setQualityComments] = useState("");

  const [selectedProduct, setSelectedProduct] = useState("");
  const [balanceAmount, setBalanceAmount] = useState();
  const [payment, setPayment] = useState("");
  const [paymentDetails, setPaymentDetails] = useState("");
  const [success, setSuccess] = useState(false);
  const [showAlert, setShowAlert] = useState(false);
  const [alertMessage, setAlertMessage] = useState("");

  const handleBalanceAmount = (event) => {
    setBalanceAmount(event.target.value);
  };

  const handleChange = (event) => {
    setPayment(event.target.value);
  };

  const handlePayment = (event) => {
    setPaymentDetails(event.target.value);
  };

  const fieldValidationPayment = (payment, paymentDetails) => {
    if (payment === "UPI" && _.isEmpty(paymentDetails)) {
      return "Please Enter UPI details";
    } else if (payment === "Card" && _.isEmpty(paymentDetails)) {
      return "Please Enter last 4 digits of Card";
    } else if (
      payment === "Card" &&
      !_.isEmpty(paymentDetails) &&
      paymentDetails.length !== 4
    ) {
      return "Enter 4 digits for card";
    } else {
      return "";
    }
  };

  const validation = async () => {
    let isValid = false;
    if (_.isEmpty(payment)) {
      setShowAlert(true);
      setAlertMessage("Payment mode not chosen");
      isValid = false;
    } else if (!isEmpty(payment)) {
      if (payment === "UPI" && isEmpty(paymentDetails)) {
        setShowAlert(true);
        setAlertMessage("Please Enter UPI details");
        isValid = false;
      } else if (payment === "Card" && isEmpty(paymentDetails)) {
        setShowAlert(true);
        setAlertMessage("Please Enter last 4 digits of Card");
        isValid = false;
      } else if (
        payment === "Card" &&
        !isEmpty(paymentDetails) &&
        paymentDetails.length !== 4
      ) {
        setShowAlert(true);
        setAlertMessage("Enter 4 digits for card");
        isValid = false;
      } else {
        isValid = true;
      }
    } else {
      isValid = true;
    }
    return isValid;
  };

  const handleContinueButton = async () => {
    const valid = await validation();
    if (valid) {
      const orderId = {
        orderId: order?.orderId,
        balanceAmount: (order.inclGst - order.paidAmount).toFixed(2),
        payment: {
          mode: payment,
          comments: paymentDetails,
        },
      };
      dispatch(closeOrder(orderId));
    }
  };

  const StyledTableRow = styled(TableRow)(({ theme }) => ({
    td: {
      border: 0,
    },
  }));

  const handleDelivery = (event, id) => {
    setDelivery(true);
    setSelectedProduct(id);
  };

  const handleCheckbox = (event) => {
    setChecked(event.target.checked);
  };

  const handleConfirmDelivery = () => {
    const date = new Date(Date.now());
    const obj = {
      orderId: order?.orderId,
      product_id: selectedProduct,
      comments: deliveryComments,
      deliveryDetails: {
        deliveryDate: date,
      },
    };
    dispatch(confirmDelivery(obj));
    props.handleBack();
  };

  const handleDeliveryComments = (event) => {
    setDeliveryComments(event.target.value);
  };

  const handleQuality = (event, id) => {
    setQuality(true);
    setSelectedProduct(id);
  };

  const handleQualitySave = () => {
    const obj = {
      orderId: order?.orderId,
      status: "QC",
      comments: qualityComments,
      products: [
        {
          product_id: selectedProduct,
          status: qualityCheckBox === "pass" ? "QC Passed" : "QC Failed",
        },
      ],
    };
    dispatch(qualitySave(obj));
    props.handleBack();
  };

  const handleQualityComments = (event) => {
    setQualityComments(event.target.value);
  };

  const handleQualityCheckBox = (event) => {
    setQualityCheckBox(event.target.value);
  };

  const handleClose = () => {
    setDelivery(false);
    setQuality(false);
    setSuccess(false);
  };

  const handleViewDetails = (event, product) => {
    setViewDetail(true);
    setViewDetailData(product);
  };
  const handleViewClose = () => {
    setViewDetail(false);
  };

  const handleReturn = () => { };

  const handleBack = () => {
    props?.handleBack();
  };

  const quantity = 1;

  let gstPercent = 12;

  var subTotal = 0;

  const taxableAmount = products.map((product) => {
    gstPercent =
      _.isEmpty(product.lensItems) && product?.name.includes("SUNGLASSES")
        ? 18
        : 12;
    const lensPrice = _.isEmpty(product.lensItems)
      ? 0
      : product.lensItems.price;
    subTotal += product.price * quantity + lensPrice * quantity;
    return 0;
  });

  const gstvalue =
    order?.couponValue > 0
      ? ((subTotal - order?.couponValue) * gstPercent) / 100
      : (subTotal * gstPercent) / 100;

  useEffect(() => {
    if (!_.isEmpty(closeOrderSuccessMessage)) {
      setSuccess(true);
    }
  }, [closeOrderSuccessMessage]);

  useEffect(() => {
    setBalanceAmount(order.inclGst - parseInt(order.paidAmount));
  }, [order]);

  //const totalAmount = coupon ? subTotal + taxAmt - 200 : subTotal + taxAmt;
  const generateStatusBullet = (data) => {
    switch (data) {
      case "Delivered": {
        return "#b7e6cf";
      }
      case "Shipped": {
        return "#d9ebff";
      }
      case "QC": {
        return "#d1efef";
      }
      case "QC Failed": {
        return "#ffb1b2";
      }
      case "Created": {
        return "#d1efef";
      }
      case "Intend Created": {
        return "#ffeacd";
      }
      case "Packed": {
        return "#d1efef";
      }
      case "Completed": {
        return "#d9ebff";
      }
      case "QC Passed": {
        return "#b7e6cf";
      }
      default: {
        return "#d1efef";
      }
    }
  };

  const statusColor = (data) => {
    switch (data) {
      case "Delivered": {
        return "#1daf67";
      }
      case "Shipped": {
        return "#0077ff";
      }
      case "Packed": {
        return "#03a5a5";
      }
      case "QC": {
        return "#03a5a5";
      }
      case "QC Failed": {
        return "#ff0000";
      }
      case "Created": {
        return "#03a5a5";
      }
      case "Intend Created": {
        return "#ffb95a";
      }
      case "Completed": {
        return "#0077ff";
      }
      case "QC Passed": {
        return "#1daf67";
      }
      default: {
        return "#03a5a5";
      }
    }
  };

  const handleAlertClose = () => {
    setShowAlert(false);
    setAlertMessage("");
    dispatch(closeOrderError(""));
  };

  return (
    <>
      <div
        style={{
          backgroundColor: "rgb(245, 245, 245)",
          paddingTop: "30px",
        }}
      >
        {closeOrderErrorMessage ? (
          <AlertComp
            type="error"
            message={closeOrderErrorMessage}
            handleAlertClose={handleAlertClose}
          />
        ) : (
          ""
        )}
        {showAlert ? (
          <AlertComp
            type="error"
            message={alertMessage}
            handleAlertClose={handleAlertClose}
          />
        ) : (
          ""
        )}
        <Grid container sx={{ pb: "70px" }}>
          <Grid item xs={12} md={8} lg={8}>
            <CheckoutBox header="Consumer Details">
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  p: "10px",
                }}
              >
                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                    paddingRight: "25px",
                  }}
                >
                  <FrameIcon />
                  <Typography sx={{ fontWeight: 500 }}>
                    {order?.customerDetails?.name}
                  </Typography>
                </div>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                    paddingRight: "25px",
                  }}
                >
                  <CallIcon />
                  <Typography sx={{ fontWeight: 500 }}>
                    +91{order?.customerDetails?.phoneNumber}
                  </Typography>
                </div>

                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                    paddingRight: "25px",
                  }}
                >
                  <SmsIcon />
                  <Typography sx={{ fontWeight: 500 }}>
                    {order?.customerDetails?.emailId}
                  </Typography>
                </div>
              </Box>
            </CheckoutBox>

            <CheckoutBox header="Product Summary">
              {products.map((product) => (
                <>
                  <TableContainer component={Box}>
                    <Table sx={{ minWidth: 700 }} aria-label="customized table">
                      <TableHead>
                        <TableRow>
                          <TableCell sx={{ fontWeight: 650 }} width={"400px"}>
                            Product Description
                          </TableCell>
                          <TableCell sx={{ fontWeight: 650 }} align="left">
                            Unit Cost(&#8377;)
                          </TableCell>
                          <TableCell sx={{ fontWeight: 650 }} align="left">
                            GST
                          </TableCell>
                          <TableCell sx={{ fontWeight: 650 }} align="left">
                            Qty
                          </TableCell>
                          <TableCell sx={{ fontWeight: 650 }} align="left">
                            Total Cost(&#8377;)
                          </TableCell>
                        </TableRow>
                      </TableHead>

                      {
                        <TableBody>
                          <StyledTableRow>
                            <TableCell sx={{ pt: "10px", pb: "0px" }}>
                              {/* <Typography
                                  sx={{
                                    color: "#03a5a5",
                                    fontSize: "10px",
                                    letterSpacing: "0.5px",
                                  }}
                                >
                                  { product.status === "QC Passed" ? "Ready For Customer" : product.status }
                                </Typography> */}
                              <div
                                style={{
                                  display: "flex",
                                  alignItems: "center",
                                }}
                              >
                                <div
                                  style={{
                                    height: "24px",
                                    backgroundColor: generateStatusBullet(
                                      product.status
                                    ),
                                    display: "inline-block",
                                    padding: "4px 3px 4px 0px",
                                    borderRadius: "4px",
                                  }}
                                >
                                  <Typography
                                    sx={{
                                      color: statusColor(product.status),
                                      fontSize: "11px",
                                      fontWeight: 500,
                                      // lineHeight: 1.14,
                                      letterSpacing: "0.42px",
                                      textAlign: "center",
                                      padding: "2px 2px 0px 4px ",
                                    }}
                                  >
                                    {product.status === "QC Passed"
                                      ? "Ready For Customer"
                                      : product.status}
                                  </Typography>
                                </div>
                              </div>
                            </TableCell>
                          </StyledTableRow>
                          <StyledTableRow key={product.productId}>
                            <TableCell align="left">{product.name}</TableCell>
                            <TableCell align="left">
                              {Number(product.price).toFixed(2)}
                            </TableCell>
                            <TableCell align="left">
                              {(Number(product.inclGst) - Number(product.inclGst)/((100 + Number(product.gst))/100)).toFixed(2)}({Number(product.gst)}%)
                            </TableCell>
                            <TableCell align="left">{quantity}</TableCell>
                            <TableCell align="left">
                              {(product.inclGst * quantity).toFixed(2)}
                            </TableCell>
                          </StyledTableRow>
                          {!_.isEmpty(product.lensItems) ? (
                            <StyledTableRow key={product.lensItems.lensId}>
                              <TableCell align="left">
                                {product.lensName}
                              </TableCell>
                              <TableCell align="left">
                                {product.lensItems.price}
                              </TableCell>
                              <TableCell align="left">
                              {(Number(product.lensItems.inclGst) - Number(product.lensItems.inclGst)/((100 + Number(product.lensItems.gst))/100)).toFixed(2)}({Number(product.gst)}%)
                              </TableCell>
                              <TableCell align="left">{quantity}</TableCell>
                              <TableCell align="left">
                                {(product.lensItems.inclGst * quantity).toFixed(
                                  2
                                )}
                              </TableCell>
                            </StyledTableRow>
                          ) : (
                            ""
                          )}
                        </TableBody>
                      }
                    </Table>
                  </TableContainer>
                  <Divider />
                  {product.status === "Shipped" ||
                    product.status === "Delivered" ||
                    product.status === "QC Failed" ? (
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "space-evenly",
                        alignItems: "center",
                        marginBottom: "20px",
                      }}
                    >
                      <div
                        style={{
                          padding: "20px",
                          textAlign: "center",
                          color: "#ff6f00",
                        }}
                        onClick={(event) => handleViewDetails(event, product)}
                      >
                        <Typography
                          sx={{
                            fontSize: "10pt",
                            fontWeight: "600",
                            cursor: "pointer",
                          }}
                        >
                          View Prescription &amp; Frame Size
                        </Typography>
                      </div>
                      <div
                        style={{
                          padding: "20px",
                          textAlign: "center",
                          color: "#03a5a5",
                        }}
                        onClick={
                          product.status === "Delivered" ||
                            product.status === "QC Failed"
                            ? (event) => handleQuality(event, product?._id)
                            : (event) => handleDelivery(event, product._id)
                        }
                      >
                        <Typography
                          sx={{
                            fontSize: "10pt",
                            fontWeight: "600",
                            cursor: "pointer",
                          }}
                        >
                          {product.status === "Delivered" ||
                            product.status === "QC Failed"
                            ? "Quality Check"
                            : "Confirm Delivery"}
                        </Typography>
                      </div>
                      {product.status === "QC Failed" ? (
                        <div
                          style={{
                            padding: "20px",
                            textAlign: "center",
                            color: "#ff0000",
                          }}
                          onClick={(event) => handleReturn(event, product)}
                        >
                          <Typography
                            sx={{
                              fontSize: "10pt",
                              fontWeight: "600",
                              cursor: "pointer",
                            }}
                          >
                            Return
                          </Typography>
                        </div>
                      ) : (
                        ""
                      )}
                    </div>
                  ) : (
                    <div
                      style={{
                        padding: "20px",
                        textAlign: "center",
                        color: "#ff6f00",
                      }}
                      onClick={(event) => handleViewDetails(event, product)}
                    >
                      <Typography
                        sx={{
                          fontSize: "10pt",
                          fontWeight: "600",
                          cursor: "pointer",
                        }}
                      >
                        View Prescription &amp; Frame Size
                      </Typography>
                    </div>
                  )}
                  <Divider />
                </>
              ))}
            </CheckoutBox>

            <CheckoutBox header="Tax Summary">
              <TableContainer component={Box}>
                <Table sx={{ minWidth: 700 }} aria-label="customized table">
                  <TableHead>
                    <TableRow>
                      <TableCell sx={{ fontWeight: 650 }}>Tax(%)</TableCell>
                      <TableCell sx={{ fontWeight: 650 }} align="left">
                        Taxable Amount(&#8377;)
                      </TableCell>
                      <TableCell sx={{ fontWeight: 650 }} align="left">
                        CGST(&#8377;)
                      </TableCell>
                      <TableCell sx={{ fontWeight: 650 }} align="left">
                        SGST(&#8377;)
                      </TableCell>
                      <TableCell sx={{ fontWeight: 650 }} align="left">
                        IGST(&#8377;)
                      </TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    <StyledTableRow key={1}>
                      <TableCell align="left">{gstPercent}%</TableCell>
                      <TableCell align="left">
                        {Number(gstvalue).toFixed(2)}
                      </TableCell>
                      <TableCell align="left">
                        {Number(gstvalue / 2).toFixed(2)}
                      </TableCell>
                      <TableCell align="left">
                        {Number(gstvalue / 2).toFixed(2)}
                      </TableCell>
                      <TableCell align="left">{"-"}</TableCell>
                    </StyledTableRow>
                  </TableBody>
                </Table>
              </TableContainer>
            </CheckoutBox>
          </Grid>

          <Grid item xs={12} md={4} lg={4}>
            <Grid item>
              <CheckoutBox header="Order Details">
                <div style={{ padding: "20px", display: "flex", justifyContent: "space-between", alignItems: "center" }}>
                  <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
                    <AssignmentIcon sx={{ color: "#03a5a5", marginRight: "5px" }} />
                    <div style={{ fontWeight: 600 }}>{order.orderId}</div>
                  </div>
                  <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
                    <CalendarMonthIcon sx={{ color: "#03a5a5", marginRight: "5px" }} />
                    <div style={{ fontWeight: 600 }}>{moment(order._created_at).format('ll')}</div>
                  </div>
                </div>
              </CheckoutBox>
              {
                !isEmpty(order.refOrderId) ?
                  <CheckoutBox header="Reference Order Details">
                    <div style={{ padding: "20px", display: "flex", justifyContent: "space-between", alignItems: "center" }}>
                      <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
                        <AssignmentIcon sx={{ color: "#03a5a5", marginRight: "5px" }} />
                        <div style={{ fontWeight: 600 }}>{order.refOrderId}</div>
                      </div>
                      <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
                        <CalendarMonthIcon sx={{ color: "#03a5a5", marginRight: "5px" }} />
                        <div style={{ fontWeight: 600 }}>{moment(order.refOrderDate).format('ll')}</div>
                      </div>
                    </div>
                  </CheckoutBox> : ""
              }
              <CheckoutBox header="Payment Summary">
                <div style={{ padding: "30px 20px" }}>
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                      marginBottom: "20px",
                    }}
                  >
                    <Typography>Subtotal</Typography>
                    <div style={{ display: "flex", alignItems: "center" }}>
                      <Typography>
                        &#8377; {Number(order.subTotal).toFixed(2)}
                      </Typography>
                    </div>
                  </div>
                    <>
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "space-between",
                          alignItems: "center",
                          marginBottom: "0px",
                        }}
                      >
                        <Typography>Discount</Typography>
                        <div style={{ display: "flex", alignItems: "center" }}>
                        {order.couponValue ? (
                          <Typography>
                            &#8377; {Number(order.couponValue).toFixed(2)}
                          </Typography>
                          ):(
                            <Typography>
                            NA
                          </Typography>
                        )}
                        </div>
                      </div>
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "space-between",
                          alignItems: "center",
                          marginBottom: "20px",
                        }}
                      >
                        {order.couponCode ? (
                        <Typography sx={{ fontSize: "0.8rem" }}>
                          ({order.couponCode})
                        </Typography>):("")}
                      </div>{" "}
                    </>
                    <div
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                      marginBottom: "20px",
                      fontSize: "10pt",
                      fontWeight: "600",
                      cursor: "pointer",
                      textAlign: "center",
                      
                    }}
                  >
                    <Typography>Taxable Amount</Typography>
                    <div style={{ display: "flex", alignItems: "center" }}>
                      {order?.couponValue ? (
                      <Typography>
                        &#8377;{Number(order?.discountTotal).toFixed(2)}
                      </Typography>):(
                        <Typography>
                        &#8377;{Number(order?.subTotal).toFixed(2)}
                      </Typography>
                      )}
                    </div>
                  </div>
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                      marginBottom: "20px",
                    }}
                  >
                    <Typography>GST</Typography>
                    <div style={{ display: "flex", alignItems: "center" }}>
                      <Typography>
                        &#8377; {Number(gstvalue).toFixed(2)}
                      </Typography>
                    </div>
                  </div>
                  <div
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                    marginBottom: "20px",
                  }}
                >
                  <Typography>Round Off</Typography>
                  <div style={{ display: "flex", alignItems: "center" }}>
                    <Typography>&#8377;{Number(order?.roundOff).toFixed(2)}</Typography>
                  </div>
                </div>
                  
                  <Divider />
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                      marginTop: "10px"
                    }}
                  >
                    <Typography>Total Amount</Typography>
                    <div style={{ display: "flex", alignItems: "center" }}>
                      <Typography>&#8377; {Number(order?.inclGst).toFixed(2)}</Typography>
                    </div>
                  </div>
                </div>
                
              </CheckoutBox>
              <CheckoutBox header="Amount Paid">
                <div style={{ padding: "30px 20px" }}>
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                      marginBottom: "20px",
                    }}
                  >
                    <Typography>Advance </Typography>
                    <div style={{ display: "flex", alignItems: "center" }}>
                      <Typography>
                        &#8377; {Number(order.paidAmount).toFixed(2)} (
                        {_.lowerCase(order.payment[0].mode)})
                      </Typography>
                    </div>
                  </div>
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                      marginBottom: "20px",
                    }}
                  >
                    <Typography sx={{ fontSize: "0.8rem" }}>
                      {order.payment[0]?.comments}
                    </Typography>
                  </div>
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                    }}
                  >
                  </div>
                </div>
                <div style={{ padding: "30px 20px" }}>
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                      marginBottom: "20px",
                    }}
                  >
                    <Typography>Balance</Typography>
                    {
                      <div style={{ display: "flex", alignItems: "center" }}>
                        <Typography>
                          &#8377;{" "}
                          {Number(Number(order.inclGst) - Number(order.paidAmount)).toFixed(2)}
                        </Typography>
                      </div>
                    }
                  </div>
                </div>
              </CheckoutBox>
              {order?.status === "QC" && (
                <CheckoutBox header="Payment Mode">
                  <div style={{ padding: "10px 20px" }}>
                    <RadioGroup
                      aria-labelledby="demo-controlled-radio-buttons-group"
                      name="controlled-radio-buttons-group"
                      value={payment}
                      onChange={handleChange}
                      row
                    >
                      <FormControlLabel
                        value="Cash"
                        control={
                          <Radio
                            size="small"
                            sx={{
                              color: "#03A5A5",
                              "&.Mui-checked": {
                                color: "#03A5A5",
                              },
                            }}
                          />
                        }
                        label="Cash"
                      />
                      <FormControlLabel
                        value="UPI"
                        control={
                          <Radio
                            size="small"
                            sx={{
                              color: "#03A5A5",
                              "&.Mui-checked": {
                                color: "#03A5A5",
                              },
                            }}
                          />
                        }
                        label="UPI"
                      />
                      <FormControlLabel
                        value="Card"
                        control={
                          <Radio
                            size="small"
                            sx={{
                              color: "#03A5A5",
                              "&.Mui-checked": {
                                color: "#03A5A5",
                              },
                            }}
                          />
                        }
                        label="Card"
                      />
                    </RadioGroup>
                  </div>
                  {payment === "UPI" ? (
                    <div style={{ padding: "10px 20px 20px 20px" }}>
                      <Typography>Transaction Id</Typography>
                      <div>
                        <TextField
                          id="demo-helper-text-misaligned-no-helper"
                          fullWidth
                          onChange={handlePayment}
                          error={fieldValidationPayment(
                            payment,
                            paymentDetails
                          )}
                          helperText={fieldValidationPayment(
                            payment,
                            paymentDetails
                          )}
                          InputLabelProps={{
                            shrink: true,
                            disableAnimation: true,
                          }}
                        />
                      </div>
                    </div>
                  ) : (
                    ""
                  )}
                  {payment === "Card" ? (
                    <div style={{ padding: "10px 20px 20px 20px" }}>
                      <Typography>Card Number(Last 4 Digit)</Typography>
                      <div>
                        <TextField
                          id="demo-helper-text-misaligned-no-helper"
                          fullWidth
                          error={fieldValidationPayment(
                            payment,
                            paymentDetails
                          )}
                          helperText={fieldValidationPayment(
                            payment,
                            paymentDetails
                          )}
                          onChange={handlePayment}
                          InputLabelProps={{
                            shrink: true,
                            disableAnimation: true,
                          }}
                        />
                      </div>
                    </div>
                  ) : (
                    ""
                  )}
                </CheckoutBox>
              )}
              {products?.map((product, index) => {
                return (
                  <>
                    {
                      product.status === "Shipped" ||
                        product.status === "Delivered" ||
                        product.status === "QC" ||
                        product.status === "QC Failed" ||
                        product.status === "QC Passed" ? (
                        <CheckoutBox header={`Shipping Info - ${index + 1}`}>
                          <div style={{ padding: "30px 20px" }}>
                            <div
                              style={{
                                display: "flex",
                                justifyContent: "space-between",
                                alignItems: "center",
                                marginBottom: "20px",
                              }}
                            >
                              <Typography>Shipping Date</Typography>
                              <div
                                style={{ display: "flex", alignItems: "center" }}
                              >
                                <Typography>
                                  {_.isEmpty(product.shippingDetails) ? "-" : moment(
                                    product.shippingDetails.shippingDate
                                  ).format("DD/MM/YYYY")}
                                </Typography>
                              </div>
                            </div>
                            <div
                              style={{
                                display: "flex",
                                justifyContent: "space-between",
                                alignItems: "center",
                                marginBottom: "20px",
                              }}
                            >
                              <Typography>Service Provider</Typography>
                              <div
                                style={{ display: "flex", alignItems: "center" }}
                              >
                                <Typography>
                                  {_.isEmpty(product.shippingDetails) ? "-" : product.shippingDetails.serviceProvider}
                                </Typography>
                              </div>
                            </div>
                            <div
                              style={{
                                display: "flex",
                                justifyContent: "space-between",
                                alignItems: "center",
                                marginBottom: "20px",
                              }}
                            >
                              <Typography>Tracking ID</Typography>
                              <div
                                style={{ display: "flex", alignItems: "center" }}
                              >
                                <Typography>
                                  {_.isEmpty(product.shippingDetails) ? "-" : product.shippingDetails.trackingId}
                                </Typography>
                              </div>
                            </div>
                            <div
                              style={{
                                display: "flex",
                                justifyContent: "space-between",
                                alignItems: "center",
                                marginBottom: "20px",
                              }}
                            >
                              <Typography>Comments</Typography>
                              <div
                                style={{ display: "flex", alignItems: "center" }}
                              >
                                <Typography>
                                  {_.isEmpty(product.shippingDetails) ? "-" : product.shippingDetails.comments}
                                </Typography>
                              </div>
                            </div>
                          </div>
                        </CheckoutBox>
                      ) : (
                        ""
                      )}
                  </>
                );
              })}
            </Grid>
          </Grid>
        </Grid>
        {order.status === "QC" ? (
          <Footer
            handleContinueButton={handleContinueButton}
            onContinue={"Close Order"}
          />
        ) : (
          " "
        )}

        <ViewDetail
          open={viewDetail}
          handleViewClose={handleViewClose}
          data={viewDetailData}
        />
        {delivery ? (
          <div>
            <Dialog
              open={delivery}
              onClose={handleClose}
              aria-labelledby="modal-modal-title"
              aria-describedby="modal-modal-description"
              sx={{ borderRadius: "8px" }}
              PaperProps={{
                classes: { root: classes?.deliveryPaper },
              }}
            >
              <div className={classes?.deliverDialogBox}>
                <div className={classes?.alignFlex}>
                  <HeadTypography>Confirm Delivery</HeadTypography>
                  <CloseIcon onClick={handleClose} sx={{ cursor: "pointer" }} />
                </div>
                <div>
                  <div style={{ margin: "10px 0px" }}>
                    <FormControlLabel
                      label="Delivered"
                      control={
                        <Radio
                          checked={checked}
                          classes={{ checked: classes?.radio }}
                          value={"Delivered"}
                          onChange={handleCheckbox}
                        />
                      }
                    />
                  </div>
                  <div style={{ marginBottom: "20px" }}>
                    <Grid container>
                      <Grid item xs={12} md={10}>
                        <LabelTypography sx={{ pb: "8px" }}>
                          COMMENTS
                        </LabelTypography>
                        <TextField
                          fullWidth
                          multiline
                          rows={4}
                          onChange={handleDeliveryComments}
                          placeholder="Type Here..."
                          disabled={!checked}
                        />
                      </Grid>
                    </Grid>
                  </div>
                  <div>
                    <ContinueButton
                      style={{ marginLeft: "0px" }}
                      onClick={handleConfirmDelivery}
                    >
                      Confirm{" "}
                    </ContinueButton>
                  </div>
                </div>
              </div>
            </Dialog>
          </div>
        ) : (
          ""
        )}
        {quality ? (
          <div>
            <Dialog
              open={quality}
              onClose={handleClose}
              aria-labelledby="modal-modal-title"
              aria-describedby="modal-modal-description"
              sx={{ borderRadius: "8px" }}
              PaperProps={{
                classes: { root: classes?.deliveryPaper },
              }}
            >
              <div className={classes?.deliverDialogBox}>
                <div className={classes?.alignFlex}>
                  <HeadTypography>Quality Check</HeadTypography>
                  <CloseIcon />
                </div>
                <div>
                  <div style={{ margin: "10px 0px" }}>
                    <RadioGroup
                      aria-labelledby="demo-controlled-radio-buttons-group"
                      name="controlled-radio-buttons-group"
                      value={qualityCheckBox}
                      onChange={handleQualityCheckBox}
                      row
                    >
                      <FormControlLabel
                        value="pass"
                        control={
                          <Radio
                            size="small"
                            sx={{
                              color: "#03A5A5",
                              "&.Mui-checked": {
                                color: "#03A5A5",
                              },
                            }}
                          />
                        }
                        label="QC Pass"
                      />
                      <FormControlLabel
                        value="fail"
                        control={
                          <Radio
                            size="small"
                            sx={{
                              color: "#03A5A5",
                              "&.Mui-checked": {
                                color: "#03A5A5",
                              },
                            }}
                          />
                        }
                        label="QC Fail"
                      />
                    </RadioGroup>
                  </div>

                  <div style={{ marginBottom: "20px" }}>
                    <Grid container>
                      <Grid item xs={12} md={10}>
                        <LabelTypography sx={{ pb: "8px" }}>
                          COMMENTS
                        </LabelTypography>
                        <TextField
                          fullWidth
                          multiline
                          rows={4}
                          onChange={handleQualityComments}
                          placeholder="Type Here..."
                          disabled={!checked}
                        />
                      </Grid>
                    </Grid>
                  </div>

                  <div>
                    <ContinueButton
                      style={{ marginLeft: "0px" }}
                      onClick={handleQualitySave}
                    >
                      Confirm
                    </ContinueButton>
                  </div>
                </div>
              </div>
            </Dialog>
          </div>
        ) : (
          ""
        )}
        {success ? (
          <div>
            <Dialog
              open={success}
              onClose={handleClose}
              aria-labelledby="modal-modal-title"
              aria-describedby="modal-modal-description"
              sx={{ borderRadius: "8px" }}
            >
              <div style={{ padding: "20px 60px 40px" }}>
                <div style={{ textAlign: "center" }}>
                  <div style={{ paddingLeft: "25px" }}>
                    <TickIcon />
                  </div>
                  <Typography
                    id="modal-modal-description"
                    sx={{ mt: 2, mb: 3 }}
                  >
                    Order Closed Successfully
                  </Typography>
                  <ContinueButton onClick={handleBack}>
                    Back To Home
                  </ContinueButton>
                </div>
              </div>
            </Dialog>
          </div>
        ) : (
          " "
        )}
      </div>
    </>
  );
};

export default OrderCheckout;
