import {
  Alert,
  Dialog,
  DialogContent,
  TextField,
  InputAdornment,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import EyePlayLogo from "../../assets/loginLogo.svg";
import { ContinueButton } from "../../components";
import { HeadTypography } from "./styles";
import {
  getPermittedStore,
  getStoreLogin,
  setErrors,
} from "../../app/reducers/storeLoginSlice";
import { useNavigate } from "react-router-dom";
import _ from "lodash";
import { AlertComp } from "../../components/Alert";

function StoreLogin(props) {
  const dispatch = useDispatch();
  const history = useNavigate();

  const [mobileError, setMobileError] = useState("");
  const [passwordError, setPasswordError] = useState("");
  const [value, setValue] = useState({});

  const selectedStoreId = useSelector(
    (state) => state.storeLoginReducer.selectedStoreId
  );
  const apiErrors = useSelector((state) => state.storeLoginReducer.apiErrors);

  const handleChange = (e, name) => {
    if (name === "phoneNumber") {
      const number = "91";
      const appendedNumber = number.concat(e.target.value);
      setValue({ ...value, [name]: appendedNumber });
    } else {
      setValue({ ...value, [name]: e.target.value });
    }
  };

  useEffect(() => {
    const token = localStorage.getItem("eyePlay-auth-token");
    if (!_.isEmpty(selectedStoreId) && token) {
      history("/orders", {
        state: {
          heading: "Sales",
          subHeader: "Orders",
          fromStoreLogin: true,
        },
      });
    } 
    if (_.isEmpty(selectedStoreId) && token) {
       dispatch(getPermittedStore());
    } 
  }, [selectedStoreId]);

  const validation = async () => {
    let isValid = false;

    if (value.phoneNumber.length < 10) {
      isValid = false;
      setMobileError("Enter 10 digits for mobile number");
    } else if (_.isEmpty(value.password)) {
      isValid = false;
      setPasswordError("Password should not me empty");
    } else {
      isValid = true;
      setMobileError("");
      setPasswordError("");
    }

    return isValid;
  };

  const handleLogin = async () => {
    const valid = await validation();
    if (valid) {
      const obj = {
        passwordPresent: true,
        ...value,
      };
      dispatch(getStoreLogin(obj));
    }
  };

  const handleAlertClose = () => {
    dispatch(setErrors(""));
  };

  return (
    <>
      <Dialog open={true}>
        {apiErrors ? (
          <AlertComp
            type="error"
            message={apiErrors}
            handleAlertClose={handleAlertClose}
          />
        ) : (
          ""
        )}
        <DialogContent>
          <div style={{ textAlign: "center", padding: "30px" }}>
            {/* <img src={EyePlayLogo} alt={"storeLogo"} /> */}
            <HeadTypography>LOGIN INTO YOUR ACCOUNT</HeadTypography>
            <div style={{ textAlign: "initial" }}>
              <TextField
                sx={{ mb: "22px" }}
                fullWidth
                required
                helperText={mobileError}
                error={mobileError}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">+91</InputAdornment>
                  ),
                }}
                type="text"
                placeholder="Phone number"
                variant="outlined"
                onChange={(e) => handleChange(e, "phoneNumber")}
              />

              <TextField
                required
                fullWidth
                helperText={passwordError}
                error={passwordError}
                type="password"
                placeholder="Password"
                variant="outlined"
                onChange={(e) => handleChange(e, "password")}
              />
            </div>
            <ContinueButton sx={{ mt: "22px" }} onClick={handleLogin}>
              LOGIN
            </ContinueButton>
          </div>
        </DialogContent>
      </Dialog>
    </>
  );
}

export default StoreLogin;
