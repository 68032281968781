import React, { useEffect, useState } from "react";
import {
  Grid,
  Box,
  Typography,
  Divider,
  TextField,
  FormControlLabel,
  RadioGroup,
  Radio,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Dialog,
  InputAdornment,
  IconButton,
  OutlinedInput,
} from "@mui/material";
import axios from 'axios';
import CheckoutBox from "./CheckoutBox";
import { SmsIcon } from "../../../components/svg/SmsIcon";
import { CallIcon } from "../../../components/svg/CallIcon";
import { FrameIcon } from "../../../components/svg/FrameIcon";
import { TickIcon } from "../../../components/svg/TickIcon";
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { styled } from "@mui/material/styles";
import { Footer } from "../../../components";
import { useNavigate } from "react-router-dom";
import ViewDetail from "./ViewDetail";
import { useDispatch, useSelector } from "react-redux";
import {
  getCheckoutData,
  setIsFromOrder,
  clearCheckout,
  setContinueShopping,
  setCheckoutDataError,
  setCouponDataError,
} from "../../../app/reducers/checkoutSlice";
import { clearFrame } from "../../../app/reducers/frameSlice";
import { clearCatalog } from "../../../app/reducers/lenscatalogSlice";
import {
  clearLibrary,
} from "../../../app/reducers/librarySlice";
import {
  clearPrescription,
  setShowCheckout,
} from "../../../app/reducers/prescriptionSlice";
import { ContinueButton } from "../../../components/wrapperComponents";
import { isEmpty } from "lodash";
import { AlertComp } from "../../../components/Alert";
import _ from "lodash";
import { useIsMount } from "../../../common/hooks/customHooks";
import { API_URL } from "../../../utils/constants";

const Checkout = (props) => {
  const history = useNavigate();
  const dispatch = useDispatch();
  const isMount = useIsMount();
  const apiURL = `${API_URL}/client/storeorder/checkoutcalculation`;
  const headers = {
    "oic-authorization": localStorage.getItem("eyePlay-auth-token"),
    "oic-refresh-token": localStorage.getItem("eyePlay-refresh-token"),
  }

  const isDemo = useSelector((state) => state.demoReducer.isDemo);

  const isSkipped = useSelector((state) => state.libraryReducer.isSkipped);

  const isCounterSale = useSelector(
    (state) => state.libraryReducer.isCounterSale
  );
  const counterSaleBarcode = useSelector(
    (state) => state.libraryReducer.counterSaleBarcode
  );

  const userData = useSelector((state) =>
    isEmpty(state.userReducer.loginData)
      ? state.consumerReducer.consumerAdded
      : state.userReducer.loginData
  );

  const productData = useSelector((state) =>
    isDemo
      ? state.demoReducer.multiSelectProductDemo
      : state.libraryReducer.multiSelectProduct
  );

  console.log(productData, "PD");

  const orderSummary = useSelector(
    (state) => state.checkoutReducer.orderSummary
  );

  const orderSummaryError = useSelector(
    (state) => state.checkoutReducer.orderSummaryError
  );

  const couponDataError = useSelector(
    (state) => state.checkoutReducer.couponDataError
  );
  const couponData = useSelector((state) => state.checkoutReducer.couponData);
  const couponsCode = useSelector((state) => state.checkoutReducer.couponCode);

  let orderAmount = 0;

  const data = isEmpty(productData)
    ? ""
    : productData?.map((product) => {
      orderAmount +=
        (!isEmpty(product?.price) ? parseInt(product.price) : 0) +
        (!isEmpty(product?.lensItems)
          ? parseInt(product.lensItems.price)
          : 0);
      return "";
    });

  const [payment, setPayment] = useState("");
  const [paymentDetails, setPaymentDetails] = useState("");
  const [amountPaid, setAmountPaid] = useState(0);
  const [couponCode, setCouponCode] = useState(couponsCode);
  const [success, setSuccess] = useState(false);
  const [viewDetailData, setViewDetailData] = useState({});
  const [showAlert, setShowAlert] = useState(false);
  const [alertMessage, setAlertMessage] = useState("");
  const [disablePlaceOrder, setDisablePlaceOrder] = useState(false);
  const [calculatedData, setCalculatedData] = useState({});
  const [showReferalDate, setShowReferalDate] = useState(false);
  const [referalData, setReferalData] = useState({});
  const [referalDateValue, setReferalDateValue] = useState({});

  useEffect(() => {
    if (!orderSummaryError) {
      setDisablePlaceOrder(false);
    }
  }, [orderSummaryError])

  const fetchData = async (productData) => {
    try {
      const response = await axios.post(apiURL, productData, { headers: headers })
      if (response.data.data) {
        setCalculatedData(response.data.data);
      }
    } catch (e) {
      console.error(e);
    }
  };

  useEffect(() => {
    fetchData({
      products: productData
    });
  }, []);

  const handleChange = (event) => {
    setPayment(event.target.value);
    setPaymentDetails("");
  };

  const handleClose = () => {
    setSuccess(false);
  };
  const backToHome = () => {
    history("/orders", {
      state: {
        heading: "Sales",
        subHeader: "Orders",
        fromStoreLogin: true,
      },
    });
  };
  const handleContinueShoppingButton = () => {
    dispatch(setContinueShopping(true));
    dispatch(clearFrame());
    dispatch(clearCatalog());
    dispatch(clearPrescription());
    history("/library", {
      state: { heading: "Inventory", subHeader: "library" },
    });
  };

  const handleCustomer = () => {
    dispatch(setIsFromOrder(true));
    history("/login");
  };

  useEffect(() => {
    if (!_.isEmpty(orderSummary)) {
      dispatch(clearCheckout());
      dispatch(clearFrame());
      dispatch(clearCatalog());
      dispatch(clearLibrary());
      dispatch(clearPrescription());
      setSuccess(true);
    }
  }, [orderSummary]);

  const handleBackButton = () => {
    dispatch(setContinueShopping(false));
    if (isSkipped) {
      dispatch(setShowCheckout(true));
      history("/productDescription");
    } else {
      history("/frame-orientation");
    }
  };

  const StyledTableRow = styled(TableRow)(({ theme }) => ({
    td: {
      border: 0,
    },
  }));

  const [viewDetail, setViewDetail] = useState(false);

  const handleReferalChange = (value, name) => {
    setShowReferalDate(false);
    if(name === "refOrderDate"){
      const date = new Date(value).toISOString();
      setReferalDateValue({ ...referalDateValue, [name]: value });
      setReferalData({ ...referalData, [name]: date })
    }
    else {
      setReferalData({ ...referalData, [name]: value })
    }
    
  };

  const handleViewDetails = (event, product) => {
    setViewDetail(true);
    setViewDetailData(product);
  };

  const handleViewClose = () => {
    setViewDetail(false);
  };

  const handleAmountPaid = (event) => {
    setAmountPaid(event.target.value);
  };

  const fieldValidation = (amount) => {
    const reg = new RegExp(/^\d*\.?\d*$/);
    if (reg.test(amount)) {
      if (isEmpty(amount) && !isMount) {
        return "Amount Paid cannot be empty";
      } else if (
        !isEmpty(amount) &&
        Number(amount) < 100 &&
        !isMount
      ) {
        return "Amount Paid cannot be less than 100";
      }
     
      else {
        return "";
      }
    } else {
      return "Enter Numbers please";
    }
  };

  const fieldValidationPayment = (payment, paymentDetails) => {
    const reg = new RegExp("^[0-9]*$");
    const regex = new RegExp("^[a-zA-Z0-9_.-]*$");

    if (payment === "UPI" && isEmpty(paymentDetails)) {
      return "Please Enter UPI details";
    } else if (payment === "UPI" && !regex.test(paymentDetails)) {
      return "Please Enter text/numbers only";
    } else if (payment === "Card" && isEmpty(paymentDetails)) {
      return "Please Enter last 4 digits of Card";
    } else if (!reg.test(paymentDetails) && payment === "Card") {
      return "Only Numbers for card";
    } else if (
      payment === "Card" &&
      !isEmpty(paymentDetails) &&
      paymentDetails.length !== 4
    ) {
      return "Enter 4 digits for card";
    } else {
      return "";
    }
  };

  const handleCoupon = (event) => {
    setCouponCode(event.target.value);
  };

  const handleCheckCoupon = () => {
    fetchData({
      products: productData,
      couponCode: couponCode,
    })
  };

  const handlePayment = (event) => {
    setPaymentDetails(event.target.value);
  };

  const handleAlertClose = () => {
    setShowAlert(false);
    dispatch(setCheckoutDataError(""));
    dispatch(setCouponDataError(""));
  };

  const validation = async () => {
    let isValid = false;
    if (isEmpty(userData)) {
      setShowAlert(true);
      setAlertMessage("Customer Details Not Added");
      isValid = false;
    } else if (isEmpty(payment)) {
      setShowAlert(true);
      setAlertMessage("Payment mode not chosen");
      isValid = false;
    } else if (isEmpty(amountPaid)) {
      setShowAlert(true);
      setAlertMessage("Amount Paid cannot be empty");
      isValid = false;
    } else if (
      !isEmpty(amountPaid) &&
      (Number(amountPaid) < 100)
    ) {
      setShowAlert(true);
      setAlertMessage("Amount Paid cannot be less than 100");
      isValid = false;
    }
    // else if (
    //   !isEmpty(amountPaid) &&
    //   parseInt(amountPaid) !== parseInt(calculatedData.inclGst) &&
    //   isCounterSale
    // ) {
    //   setShowAlert(true);
    //   setAlertMessage("Amount Paid need to be same as totalAmount");
    //   isValid = false;
    // } 
    else if ((!isEmpty(amountPaid)) && (Number(amountPaid) < 100)) {
      setShowAlert(true);
      setAlertMessage("Amount Paid cannot be less than 100");
      isValid = false;
    } else if (!isEmpty(payment)) {
      if (payment === "UPI" && isEmpty(paymentDetails)) {
        setShowAlert(true);
        setAlertMessage("Please Enter UPI details");
        isValid = false;
      } else if (payment === "Card" && isEmpty(paymentDetails)) {
        setShowAlert(true);
        setAlertMessage("Please Enter last 4 digits of Card");
        isValid = false;
      } else if (
        payment === "Card" &&
        !isEmpty(paymentDetails) &&
        paymentDetails.length !== 4
      ) {
        setShowAlert(true);
        setAlertMessage("Enter 4 digits for card");
        isValid = false;
      } else {
        isValid = true;
      }
    } else {
      isValid = true;
    }
    return isValid;
  };

  const handleContinueButton = async () => {
    const storeId = localStorage.getItem("storeId");
    const isValid = await validation();
    if (isValid) {
      if (calculatedData.discountValue) {
        const orderData = {
          storeId: storeId,
          payment: {
            mode: payment,
            comments: paymentDetails,
          },
          couponCode: couponCode,
          couponValue: String(calculatedData.discountValue),
          paidAmount: amountPaid,
          products: productData.map((product, index) => {
            return {
              productId: product.productId,
              costPrice: product.costPrice,
              name: product.name,
              price: product.price,
              brandType: product.brandType,
              prescriptions: product.prescriptions,
              lensItems: product.lensItems,
              frameSize: product.frameSize,
              counterSale: isCounterSale ? true : false,
              barcodeId: isCounterSale ? counterSaleBarcode[index] : "",
            };
          }),
          customerDetails: {
            name: userData?.customerName,
            customerId: userData?._id,
            phoneNumber: userData?.phoneNumber,
            emailId: userData?.emailId,
          },
        };
        if(!isEmpty(referalData.refOrderId) && !isEmpty(referalData.refOrderDate)){
          orderData.refOrderId = referalData.refOrderId;
          orderData.refOrderDate = referalData.refOrderDate;
        }
        dispatch(getCheckoutData(orderData));
        setDisablePlaceOrder(true);
      } else {
        const orderData = {
          storeId: storeId,
          payment: {
            mode: payment,
            comments: paymentDetails,
          },          
          paidAmount: amountPaid,
          products: productData.map((product) => {
            return {
              productId: product.productId,
              costPrice: product.costPrice,
              name: product.name,
              price: product.price,
              brandType: product.brandType,
              prescriptions: product.prescriptions,
              lensItems: product.lensItems,
              frameSize: product.frameSize,
              counterSale: false,
              barcodeId: ""
            };
          }),
          customerDetails: {
            name: userData?.customerName,
            customerId: userData?._id,
            phoneNumber: userData?.phoneNumber,
            emailId: userData?.emailId,
          },
        };
        if(!isEmpty(referalData.refOrderId) && !isEmpty(referalData.refOrderDate)){
          orderData.refOrderId = referalData.refOrderId;
          orderData.refOrderDate = referalData.refOrderDate;
        }
        dispatch(getCheckoutData(orderData));
        setDisablePlaceOrder(true);
      }
    }
  };

  return (
    <div
      style={{
        backgroundColor: "rgb(245, 245, 245)",
        padding: "10px",
      }}
    >
      {orderSummaryError ? (
        <AlertComp
          type="error"
          message="Something went wrong"
          handleAlertClose={handleAlertClose}
        />
      ) : (
        ""
      )}
      {showAlert ? (
        <AlertComp
          type="error"
          message={alertMessage}
          handleAlertClose={handleAlertClose}
        />
      ) : (
        ""
      )}
      <Grid container xs={12} md={12} lg={12} sx={{ pb: "70px" }}>
        <Grid item xs={12} md={8} lg={8}>
          <CheckoutBox header="Consumer Details">
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                p: "10px",
              }}
            >
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                  paddingRight: "25px",
                }}
              >
                <FrameIcon />
                <Typography sx={{ fontWeight: 500 }}>
                  {userData?.customerName}
                </Typography>
              </div>
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                  paddingRight: "25px",
                }}
              >
                <CallIcon />
                <Typography sx={{ fontWeight: 500 }}>
                  +91{userData?.phoneNumber}
                </Typography>
              </div>

              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                  paddingRight: "25px",
                }}
              >
                <SmsIcon />
                <Typography sx={{ fontWeight: 500 }}>
                  {userData?.emailId}
                </Typography>
              </div>
            </Box>
          </CheckoutBox>

          <CheckoutBox header="Product Summary">
            {productData.map((product) => (
              <>
                <TableContainer component={Box}>
                  <Table sx={{ minWidth: 700 }} aria-label="customized table">
                    <TableHead>
                      <TableRow>
                        <TableCell sx={{ fontWeight: 650 }} width={"400px"}>
                          Product Description
                        </TableCell>
                        <TableCell sx={{ fontWeight: 650 }} align="left">
                          Unit Cost(&#8377;)
                        </TableCell>
                        <TableCell sx={{ fontWeight: 650 }} align="left">
                          GST
                        </TableCell>
                        <TableCell sx={{ fontWeight: 650 }} align="left">
                          Qty
                        </TableCell>
                        <TableCell sx={{ fontWeight: 650 }} align="left">
                          Total Cost(&#8377;)
                        </TableCell>
                      </TableRow>
                    </TableHead>
                    {
                      <TableBody>
                        <StyledTableRow key={product.productId}>
                          <TableCell align="left">{product.name}</TableCell>
                          <TableCell align="left">{product.price}</TableCell>
                          {/* <TableCell align="left">
                            {(
                              (product.price *
                                (product.name.includes("EYEGLASSES")
                                  ? 12
                                  : 18)) /
                              100
                            )
                              .toString()
                              .concat(
                                `(${
                                  product.name.includes("EYEGLASSES") ? 12 : 18
                                })%`
                              )}
                          </TableCell> */}
                          <TableCell align="left">{product.gstValue.toFixed(2)}({product.gst}%)</TableCell>
                          <TableCell align="left">1</TableCell>
                          <TableCell align="left">
                            {Number(product.total).toFixed(2)}
                          </TableCell>
                        </StyledTableRow>
                        {!isEmpty(product.lensItems) ? (
                          <StyledTableRow key={product.lensItems.lensId}>
                            <TableCell align="left">
                              {
                                product.lensItems.brand
                                + " " +
                                product.lensItems.lensType
                                + " " +
                                product.lensItems.lensCoating
                                + " " +
                                product.lensItems.index
                              }
                            </TableCell>
                            <TableCell align="left">
                              {product.lensItems.price}
                            </TableCell>
                            <TableCell align="left">{Number(product.lensItems?.gstValue).toFixed(2)}({product.lensItems.gst}%)</TableCell>
                            <TableCell align="left">1</TableCell>
                            <TableCell align="left">
                              {Number(product.lensItems.total).toFixed(2)}
                            </TableCell>
                          </StyledTableRow>
                        ) : (
                          ""
                        )}
                      </TableBody>
                    }
                  </Table>
                </TableContainer>
                <Divider />
                <div
                  style={{
                    padding: "20px",
                    textAlign: "center",
                    color: "#ff6f00",
                  }}
                  onClick={(event) => handleViewDetails(event, product)}
                >
                  <Typography
                    sx={{
                      fontSize: "10pt",
                      fontWeight: "600",
                      cursor: "pointer",
                    }}
                  >
                    View Prescription &amp; Frame Size
                  </Typography>
                </div>
                <Divider />
              </>
            ))}
          </CheckoutBox>

          <CheckoutBox header="Tax Summary">
            <TableContainer component={Box}>
              <Table sx={{ minWidth: 700 }} aria-label="customized table">
                <TableHead>
                  <TableRow>
                    <TableCell sx={{ fontWeight: 650 }}>Tax(%)</TableCell>
                    <TableCell align="left" sx={{ fontWeight: 650 }}>
                      Taxable Amount(&#8377;)
                    </TableCell>
                    <TableCell align="left" sx={{ fontWeight: 650 }}>
                      CGST(&#8377;)
                    </TableCell>
                    <TableCell align="left" sx={{ fontWeight: 650 }}>
                      SGST(&#8377;)
                    </TableCell>
                    <TableCell align="left" sx={{ fontWeight: 650 }}>
                      IGST(&#8377;)
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  <StyledTableRow key={1}>
                    <TableCell align="left">{calculatedData.gst}%</TableCell>
                    <TableCell align="left">
                      {calculatedData.totalGst}
                    </TableCell>
                    <TableCell align="left">
                      {calculatedData.cgst}
                    </TableCell>
                    <TableCell align="left">
                      {calculatedData.sgst}
                    </TableCell>
                    <TableCell align="left">{"-"}</TableCell>
                  </StyledTableRow>
                </TableBody>
              </Table>
            </TableContainer>
          </CheckoutBox>
        </Grid>

        <Grid item xs={12} md={4} lg={4}>
          <Grid item>
            <CheckoutBox header="Reference Order Details">
              <div style={{ padding: "20px", display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center" }}>
                <TextField
                  id="demo-helper-text-misaligned-no-helper"
                  placeholder="Order ID"
                  sx={{ width: "100%", marginBottom: "20px" }}
                  inputProps={{
                    style: { textAlign: "left" },
                  }}
                  onChange={(event) => handleReferalChange(event.target.value, "refOrderId")}
                ></TextField>
                <DatePicker
                  selected={referalDateValue.refOrderDate}
                  onChange={(date) => handleReferalChange(date, "refOrderDate")}
                  dateFormat="dd/MM/yyyy"
                  peekNextMonth
                  showMonthDropdown
                  showYearDropdown
                  open={showReferalDate}
                  closeCalendar={showReferalDate}
                  dropdownMode="select"
                  placeholderText="Order Date"
                  customInput={
                    <OutlinedInput
                  id="outlined-adornment-password"
                  fullWidth
                  placeholder="Order Date"
                  value={referalData.refOrderDate || ""}
                  InputLabelProps={{ shrink: true, disableAnimation: true }}
                  endAdornment={
                    <InputAdornment position="end">
                      <IconButton
                        onClick={() => setShowReferalDate(!showReferalDate)}
                        aria-label="toggle password visibility"
                        edge="end"
                        sx={{
                          color: "#03a5a5",
                          fontSize: "12px",
                          marginLeft: "2px",
                        }}
                      >
                        <CalendarMonthIcon />
                      </IconButton>
                    </InputAdornment>
                  }
                />
              }/>
              </div>
            </CheckoutBox>
            <CheckoutBox header="Apply Coupon Code">
              <div style={{ padding: "20px" }}>
                {couponDataError ? (
                  <AlertComp
                    type="warning"
                    message={couponDataError}
                    handleAlertClose={handleAlertClose}
                  />
                ) : (
                  ""
                )}
                <OutlinedInput
                  id="outlined-adornment-password"
                  fullWidth
                  onChange={(event) => handleCoupon(event)}
                  value={couponCode}
                  InputLabelProps={{ shrink: true, disableAnimation: true }}
                  endAdornment={
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="toggle password visibility"
                        onClick={handleCheckCoupon}
                        edge="end"
                        sx={{
                          color: "#ff6f00",
                          fontSize: "12px",
                          marginLeft: "2px",
                        }}
                      >
                        APPLY
                      </IconButton>
                    </InputAdornment>
                  }
                />
                <Typography
                  sx={{
                    color: "#ff6f00",
                    fontSize: "12px",
                    marginLeft: "2px",
                  }}
                >
                  {calculatedData.couponCodeMessage ? calculatedData.couponCodeMessage : ""}
                </Typography>
              </div>
            </CheckoutBox>

            <CheckoutBox header="Payment Summary">
              <div style={{ padding: "30px 20px" }}>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                    marginBottom: "20px",
                  }}
                >
                  <Typography>Sub Total</Typography>
                  <div style={{ display: "flex", alignItems: "center" }}>
                    <Typography>&#8377;{calculatedData?.subTotal?.toFixed(2)}</Typography>
                  </div>
                </div>
                {/* {calculatedData.discountValue ? ( */}
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                      marginBottom: "20px",
                      fontSize: "10pt",
                      fontWeight: "600",
                      cursor: "pointer",
                      textAlign: "center",
                      color: "#ff6f00",
                    }}
                  >
                    <Typography>Discount</Typography>
                    <div style={{ display: "flex", alignItems: "center" }}>
                      {calculatedData.discountValue ? (
                      <Typography>
                        -&#8377;{calculatedData.discountValue}
                      </Typography>):(
                        <Typography>
                        NA
                      </Typography>
                      )}
                    </div>
                  </div>
                {/* ) : ( */}
                {/* )} */}
                {/* {calculatedData.discountValue ? ( */}
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                      marginBottom: "20px",
                      fontSize: "10pt",
                      fontWeight: "600",
                      cursor: "pointer",
                      textAlign: "center",
                      
                    }}
                  >
                    <Typography>Taxable Amount</Typography>
                    <div style={{ display: "flex", alignItems: "center" }}>
                      {calculatedData?.discountAmount ? (
                      <Typography>
                        &#8377;{Number(calculatedData?.discountAmount).toFixed(2)}
                      </Typography>):(
                        <Typography>
                        &#8377;{Number(calculatedData?.subTotal).toFixed(2)}
                      </Typography>
                      )}
                    </div>
                  </div>
                {/* ) : (
                  ""
                )} */}
                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                    marginBottom: "20px",
                  }}
                >
                  <Typography>GST</Typography>
                  <div style={{ display: "flex", alignItems: "center" }}>
                    <Typography>&#8377;{calculatedData.totalGst}</Typography>
                  </div>
                </div>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                    marginBottom: "20px",
                  }}
                >
                  <Typography>Round Off</Typography>
                  <div style={{ display: "flex", alignItems: "center" }}>
                    <Typography>&#8377;{Number(_.round(calculatedData?.inclGst) - calculatedData?.inclGst).toFixed(2)}</Typography>
                  </div>
                </div>
                <Divider />
                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                    marginTop: '10px'
                  }}
                >
                  <Typography>Total Amount</Typography>
                  <div style={{ display: "flex", alignItems: "center" }}>
                    <Typography>&#8377;{Number(Number(calculatedData?.inclGst) + Number((_.round(calculatedData?.inclGst)) - calculatedData?.inclGst)).toFixed(2)}</Typography>
                  </div>
                </div>
              </div>
             
              
            </CheckoutBox>

            <CheckoutBox header="Amount Paid">
              <div style={{ padding: "30px 20px" }}>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                    marginBottom: "20px",
                  }}
                >
                  <Typography>Advance</Typography>
                  <div style={{ display: "flex", alignItems: "center" }}>
                    <TextField
                      id="demo-helper-text-misaligned-no-helper"
                      placeholder="0.00"
                      sx={{ width: "130px" }}
                      inputProps={{
                        style: { textAlign: "right" },
                        inputMode: "numeric",
                        pattern: "[0-9]*",
                      }}
                      onChange={handleAmountPaid}
                      error={fieldValidation(amountPaid)}
                      helperText={fieldValidation(amountPaid)}
                    ></TextField>
                  </div>
                </div>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                    marginBottom: "20px",
                  }}
                >
                  <Typography>Balance</Typography>
                  <div style={{ display: "flex", alignItems: "center" }}>
                    <Typography>
                      &#8377;
                      {calculatedData.inclGst && (
                        (Number(Number(calculatedData?.inclGst) + Number((_.round(calculatedData?.inclGst)) - calculatedData?.inclGst) - amountPaid)).toFixed(2)
                      )}

                    </Typography>
                  </div>
                </div>
              </div>
            </CheckoutBox>

            <CheckoutBox header="Payment Mode">
              <div style={{ padding: "10px 20px" }}>
                <RadioGroup
                  aria-labelledby="demo-controlled-radio-buttons-group"
                  name="controlled-radio-buttons-group"
                  value={payment}
                  onChange={handleChange}
                  row
                >
                  <FormControlLabel
                    value="Cash"
                    control={
                      <Radio
                        size="small"
                        sx={{
                          color: "#03A5A5",
                          "&.Mui-checked": {
                            color: "#03A5A5",
                          },
                        }}
                      />
                    }
                    label="Cash"
                  />
                  <FormControlLabel
                    value="UPI"
                    control={
                      <Radio
                        size="small"
                        sx={{
                          color: "#03A5A5",
                          "&.Mui-checked": {
                            color: "#03A5A5",
                          },
                        }}
                      />
                    }
                    label="UPI"
                  />
                  <FormControlLabel
                    value="Card"
                    control={
                      <Radio
                        size="small"
                        sx={{
                          color: "#03A5A5",
                          "&.Mui-checked": {
                            color: "#03A5A5",
                          },
                        }}
                      />
                    }
                    label="Card"
                  />
                </RadioGroup>
              </div>
              {payment === "UPI" ? (
                <div style={{ padding: "10px 20px 20px 20px" }}>
                  <Typography>Transaction Id</Typography>
                  <div>
                    <TextField
                      id="demo-helper-text-misaligned-no-helper"
                      fullWidth
                      error={fieldValidationPayment(payment, paymentDetails)}
                      helperText={fieldValidationPayment(
                        payment,
                        paymentDetails
                      )}
                      onChange={handlePayment}
                      InputLabelProps={{ shrink: true, disableAnimation: true }}
                    />
                  </div>
                </div>
              ) : (
                ""
              )}
              {payment === "Card" ? (
                <div style={{ padding: "10px 20px 20px 20px" }}>
                  <Typography>Card Number(Last 4 Digit)</Typography>
                  <div>
                    <TextField
                      id="demo-helper-text-misaligned-no-helper"
                      fullWidth
                      error={fieldValidationPayment(payment, paymentDetails)}
                      helperText={fieldValidationPayment(
                        payment,
                        paymentDetails
                      )}
                      onChange={handlePayment}
                      InputLabelProps={{ shrink: true, disableAnimation: true }}
                    />
                  </div>
                </div>
              ) : (
                ""
              )}
            </CheckoutBox>
          </Grid>
        </Grid>
      </Grid>
      <Footer
        addCustomer={isEmpty(userData) ? "Add Consumer" : ""}
        handleCustomer={handleCustomer}
        back={"Back"}
        handleBackButton={handleBackButton}
        handleContinueButton={handleContinueButton}
        handleContinueShopping={handleContinueShoppingButton}
        continueShopping={"Continue Shopping"}
        // disableContinueShopping={isCounterSale}
        onContinue={(!disablePlaceOrder) ? "Place Order" : "Placing Order Please wait ...."}
        disablePlaceOrder={disablePlaceOrder}
      />
      <ViewDetail
        open={viewDetail}
        handleViewClose={handleViewClose}
        data={viewDetailData}
      />
      {success ? (
        <div>
          <Dialog
            open={success}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
            sx={{ borderRadius: "8px" }}
          >
            <div style={{ padding: "20px 60px 40px" }}>
              <div style={{ textAlign: "center" }}>
                <div style={{ paddingLeft: "25px" }}>
                  <TickIcon />
                </div>
                <Typography id="modal-modal-description" sx={{ mt: 2, mb: 3 }}>
                  Order Place Successfully
                </Typography>
                <ContinueButton onClick={backToHome}>
                  Back To Home
                </ContinueButton>
              </div>
            </div>
          </Dialog>
        </div>
      ) : (
        " "
      )}
    </div>
  );
};

export default Checkout;
